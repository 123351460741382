//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CapturesService } from './captures.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CapturesServiceEntitySetConfig = {
  name: 'Captures',
  entityType: 'paplusApi.DataDomain.EFEntities.CaptureEdm',
  service: CapturesService
} as EntitySetConfig;
//#endregion