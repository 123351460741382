//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MediaContributorJobAssetsService } from './mediacontributorjobassets.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const MediaContributorJobAssetsServiceEntitySetConfig = {
  name: 'MediaContributorJobAssets',
  entityType: 'paplusApi.DataDomain.EFEntities.MediaContributorJobAssetEdm',
  service: MediaContributorJobAssetsService
} as EntitySetConfig;
//#endregion