//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetEdm } from './jobassetedm.entity';
//#endregion

export interface JobAssetParentAssetEdm {
  //#region ODataApiGen Properties
  jobAssetGuid?: string;
  jobParentAssetGuid?: string;
  jobAsset?: JobAssetEdm;
  jobParentAssetGUIDJobAsset?: JobAssetEdm;
  //#endregion
}