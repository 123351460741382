//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MediaContributorJobAssetEdm } from './mediacontributorjobassetedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const MediaContributorJobAssetEdmEntityConfig = {
  name: 'MediaContributorJobAssetEdm',
  keys: [{name: 'mediaContributorJobAssetGuid'}],
  fields: {
    mediaContributorJobAssetGuid: {type: 'Edm.Guid', nullable: false},
    mediaContributorGuid: {type: 'Edm.Guid'},
    jobAssetGuid: {type: 'Edm.Guid'},
    jobAsset: {type: 'paplusApi.DataDomain.EFEntities.JobAssetEdm', navigation: true, referentials: [{property: 'jobAssetGuid', referencedProperty: 'jobAssetGuid'}]},
    mediaContributor: {type: 'paplusApi.DataDomain.EFEntities.MediaContributorEdm', navigation: true, referentials: [{property: 'mediaContributorGuid', referencedProperty: 'mediaContributorGuid'}]}
  }
} as StructuredTypeConfig<MediaContributorJobAssetEdm>;
//#endregion