//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RolePermissionsService } from './rolepermissions.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const RolePermissionsServiceEntitySetConfig = {
  name: 'RolePermissions',
  entityType: 'paplusApi.DataDomain.EFEntities.RolePermissionEdm',
  service: RolePermissionsService
} as EntitySetConfig;
//#endregion