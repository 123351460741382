
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { MsalGuard } from './aadb2cauth/msal.guard';

const routes: Routes = [
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: '',
    redirectTo: '/app',
    pathMatch: 'full',
  },
  {
    path: 'logout',
    redirectTo: '/'
  },

  {
    path: 'app',
    loadChildren: () => import('./tabs/tabs-page/tabs-page.module').then(m => m.TabsModule),
    canActivate: [
      MsalGuard
    ]
  },
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
