//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetEdm } from './jobassetedm.entity';
import { JobTemplateEdm } from './jobtemplateedm.entity';
//#endregion

export interface JobAssetTemplateEdm {
  //#region ODataApiGen Properties
  jobAssetTemplateGuid: string;
  jobTemplateGuid?: string;
  jobAssetGuid?: string;
  fromUtcDateTime: Date;
  toUtcDateTime?: Date;
  jobAsset?: JobAssetEdm;
  jobTemplate?: JobTemplateEdm;
  //#endregion
}