//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonMediaStatusEdm } from './personmediastatusedm.entity';
//#endregion

export interface MediaStatusEdm {
  //#region ODataApiGen Properties
  mediaStatusGuid: string;
  description: string;
  isAccepted?: boolean;
  personMediaStatuses?: PersonMediaStatusEdm[];
  //#endregion
}