//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RoleEdm } from './roleedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const RoleEdmEntityConfig = {
  name: 'RoleEdm',
  keys: [{name: 'roleGuid'}],
  fields: {
    roleGuid: {type: 'Edm.Guid', nullable: false},
    ouguid: {type: 'Edm.Guid'},
    description: {type: 'Edm.String', nullable: false, maxLength: -1},
    appRoleTag: {type: 'Edm.String', nullable: false},
    isSystemRole: {type: 'Edm.Boolean'},
    roleLevel: {type: 'Edm.Int32'},
    ou: {type: 'paplusApi.DataDomain.EFEntities.OuEdm', navigation: true, referentials: [{property: 'ouguid', referencedProperty: 'ouguid'}]},
    personOuRoles: {type: 'paplusApi.DataDomain.EFEntities.PersonOuRoleEdm', collection: true, navigation: true},
    projectPersonRoles: {type: 'paplusApi.DataDomain.EFEntities.ProjectPersonRoleEdm', collection: true, navigation: true},
    rolePermissions: {type: 'paplusApi.DataDomain.EFEntities.RolePermissionEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<RoleEdm>;
//#endregion