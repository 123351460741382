import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobEdm } from '../paplusApi/DataDomain/EFEntities/jobedm.entity';
import { JobAddressEdm } from '../paplusApi/DataDomain/EFEntities/jobaddressedm.entity';
import { JobTemplateEdm } from '../paplusApi/DataDomain/EFEntities/jobtemplateedm.entity';
import { OuEdm } from '../paplusApi/DataDomain/EFEntities/ouedm.entity';
import { PersonJobEdm } from '../paplusApi/DataDomain/EFEntities/personjobedm.entity';
import { ProjectEdm } from '../paplusApi/DataDomain/EFEntities/projectedm.entity';
//#endregion

@Injectable()
export class JobsService extends ODataEntitySetService<JobEdm> {
  constructor(client: ODataClient) {
    super(client, 'Jobs', 'paplusApi.DataDomain.EFEntities.JobEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public jobAddresses(key: EntityKey<JobEdm>): ODataNavigationPropertyResource<JobAddressEdm> { 
    return this.entity(key).navigationProperty<JobAddressEdm>('jobAddresses'); 
  }
  public fetchJobAddresses(key: EntityKey<JobEdm>, options?: ODataQueryArgumentsOptions<JobAddressEdm>) {
    return this.fetchNavigationProperty<JobAddressEdm>(
      this.jobAddresses(key), 
      'entities', options) as Observable<ODataEntities<JobAddressEdm>>;
  }
  public addJobAddressEdmToJobAddresses(key: EntityKey<JobEdm>, target: ODataEntityResource<ODataEntities<JobAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobAddresses(key).reference()
      .add(target);
  }
  public removeJobAddressEdmFromJobAddresses(key: EntityKey<JobEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<JobAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobAddresses(key).reference()
      .remove(target);
  }
  public jobTemplates(key: EntityKey<JobEdm>): ODataNavigationPropertyResource<JobTemplateEdm> { 
    return this.entity(key).navigationProperty<JobTemplateEdm>('jobTemplates'); 
  }
  public fetchJobTemplates(key: EntityKey<JobEdm>, options?: ODataQueryArgumentsOptions<JobTemplateEdm>) {
    return this.fetchNavigationProperty<JobTemplateEdm>(
      this.jobTemplates(key), 
      'entities', options) as Observable<ODataEntities<JobTemplateEdm>>;
  }
  public addJobTemplateEdmToJobTemplates(key: EntityKey<JobEdm>, target: ODataEntityResource<ODataEntities<JobTemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobTemplates(key).reference()
      .add(target);
  }
  public removeJobTemplateEdmFromJobTemplates(key: EntityKey<JobEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<JobTemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobTemplates(key).reference()
      .remove(target);
  }
  public ou(key: EntityKey<JobEdm>): ODataNavigationPropertyResource<OuEdm> { 
    return this.entity(key).navigationProperty<OuEdm>('ou'); 
  }
  public fetchOu(key: EntityKey<JobEdm>, options?: ODataQueryArgumentsOptions<OuEdm>) {
    return this.fetchNavigationProperty<OuEdm>(
      this.ou(key), 
      'entity', options) as Observable<ODataEntity<OuEdm>>;
  }
  public setOuEdmAsOu(key: EntityKey<JobEdm>, target: ODataEntityResource<ODataEntity<OuEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ou(key).reference()
      .set(target, {etag});
  }
  public unsetOuEdmAsOu(key: EntityKey<JobEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<OuEdm>>, etag?: string} = {}): Observable<any> {
    return this.ou(key).reference()
      .unset({etag});
  }
  public personJobs(key: EntityKey<JobEdm>): ODataNavigationPropertyResource<PersonJobEdm> { 
    return this.entity(key).navigationProperty<PersonJobEdm>('personJobs'); 
  }
  public fetchPersonJobs(key: EntityKey<JobEdm>, options?: ODataQueryArgumentsOptions<PersonJobEdm>) {
    return this.fetchNavigationProperty<PersonJobEdm>(
      this.personJobs(key), 
      'entities', options) as Observable<ODataEntities<PersonJobEdm>>;
  }
  public addPersonJobEdmToPersonJobs(key: EntityKey<JobEdm>, target: ODataEntityResource<ODataEntities<PersonJobEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personJobs(key).reference()
      .add(target);
  }
  public removePersonJobEdmFromPersonJobs(key: EntityKey<JobEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonJobEdm>>, etag?: string} = {}): Observable<any> {
    return this.personJobs(key).reference()
      .remove(target);
  }
  public project(key: EntityKey<JobEdm>): ODataNavigationPropertyResource<ProjectEdm> { 
    return this.entity(key).navigationProperty<ProjectEdm>('project'); 
  }
  public fetchProject(key: EntityKey<JobEdm>, options?: ODataQueryArgumentsOptions<ProjectEdm>) {
    return this.fetchNavigationProperty<ProjectEdm>(
      this.project(key), 
      'entity', options) as Observable<ODataEntity<ProjectEdm>>;
  }
  public setProjectEdmAsProject(key: EntityKey<JobEdm>, target: ODataEntityResource<ODataEntity<ProjectEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.project(key).reference()
      .set(target, {etag});
  }
  public unsetProjectEdmAsProject(key: EntityKey<JobEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ProjectEdm>>, etag?: string} = {}): Observable<any> {
    return this.project(key).reference()
      .unset({etag});
  }
  //#endregion
}
