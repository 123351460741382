//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProjectStatusesService } from './projectstatuses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ProjectStatusesServiceEntitySetConfig = {
  name: 'ProjectStatuses',
  entityType: 'paplusApi.DataDomain.EFEntities.ProjectStatusEdm',
  service: ProjectStatusesService
} as EntitySetConfig;
//#endregion