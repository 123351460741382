import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { GlobalEvents } from '../../shared/enum/events-enum';
import { TAB_PAGES } from '../../shared/constant/sidebar_list';
import { PubSubService } from '../../shared/services/pub-sub.service';
import { TabBarService } from '../../shared/services/tab-bar.service';



/*
 * TABS_ROOT is the root path of all pages*/

export const SIDES_ROOT: string = 'app';
export const TABS_ROOT: string = 'app/tabs';
export const APP_PAGES: any[] = TAB_PAGES.map((page: any) => {
  if (page.inSidemenu) {
    page.url = '/' + SIDES_ROOT + '/' + page.tab;
  } else {
    page.url = '/' + TABS_ROOT + '/' + page.tab;
  }
  return page;
});

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs-page.html',
  styleUrls: ['tabs-page.scss']
})
export class TabsPage {
  public tabBarPages: Array<any>=[];
  // =TAB_PAGES.filter((page: any) => page.inTabBar);

  @ViewChild('tabBar', { read: ElementRef, static: false })
  private tabBarRef: ElementRef | any;

  constructor(private tabBarService: TabBarService,
    private pubSub: PubSubService,
    private ref: ChangeDetectorRef) {
   
    this.pubSub.subscribe(GlobalEvents.PAGE_TABS, (tabs: [{ Tabs: null, parent: "" }]) => {
      this.tabBarPages = tabBarService.setPageTabs(tabs[0].Tabs);
      const pagesShowingTabBar: Set<string> = new Set<string>().add(tabs[0].parent);

      this.tabBarService.init(this.tabBarRef, pagesShowingTabBar);
      this.ref.detectChanges();
    })

  }

  public ngAfterViewInit(): void {
    this.tabBarPages = this.tabBarService.setPageTabs();
    const pagesShowingTabBar: Set<string> = new Set<string>(
      TAB_PAGES.filter((page: any) => page.showTabBar)
        .map((page: any) => page.tab));
    this.tabBarService.init(this.tabBarRef, pagesShowingTabBar);
    this.ref.detectChanges();

  }

  updateTabs(sidemenu: string, TABS: object[]) {
    if (TABS != undefined) {
      let tabArrayObject = [{ Tabs: TABS, parent: sidemenu }]
      this.pubSub.publish(GlobalEvents.PAGE_TABS, tabArrayObject);
    }
  }

}
