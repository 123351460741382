//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonOuRoleEdm } from './personouroleedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonOuRoleEdmEntityConfig = {
  name: 'PersonOuRoleEdm',
  keys: [{name: 'personOuRoleGuid'}],
  fields: {
    personOuRoleGuid: {type: 'Edm.Guid', nullable: false},
    personGuid: {type: 'Edm.Guid'},
    roleGuid: {type: 'Edm.Guid'},
    ouguid: {type: 'Edm.Guid'},
    reportingPersonGuid: {type: 'Edm.Guid'},
    fromUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    toUtcDateTime: {type: 'Edm.DateTimeOffset'},
    ou: {type: 'paplusApi.DataDomain.EFEntities.OuEdm', navigation: true, referentials: [{property: 'ouguid', referencedProperty: 'ouguid'}]},
    person: {type: 'paplusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]},
    reportingPerson: {type: 'paplusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'reportingPersonGuid', referencedProperty: 'personGuid'}]},
    role: {type: 'paplusApi.DataDomain.EFEntities.RoleEdm', navigation: true, referentials: [{property: 'roleGuid', referencedProperty: 'roleGuid'}]}
  }
} as StructuredTypeConfig<PersonOuRoleEdm>;
//#endregion