<!--<ion-tabs>-->
  <!-- menu shown in app footer -->

<!--<ion-tabs>-->
  <!-- menu shown in app footer -->

  <!--<ion-tab-bar slot="bottom">

    <ion-tab-button tab="job">
      <ion-icon name="construct"></ion-icon>
      <ion-label>Jobs</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="map">
      <ion-icon name="map"></ion-icon>
      <ion-label>Map</ion-label>
    </ion-tab-button>


  </ion-tab-bar>

</ion-tabs>-->


<ion-tabs color="secondary">
  <ion-tab-bar  [style]="tabBarPages.length > 0?'display:flex':'display:none'" #tabBar slot="bottom" >
    <ion-tab-button  *ngFor="let p of tabBarPages" [tab]="p.tab" (click)="updateTabs(p.tab,p.TABS)">
      <ion-icon  [name]="p.icon"></ion-icon>
      <ion-label>{{ p.title | translate }}</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
