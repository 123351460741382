//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAddressesService } from './jobaddresses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const JobAddressesServiceEntitySetConfig = {
  name: 'JobAddresses',
  entityType: 'paplusApi.DataDomain.EFEntities.JobAddressEdm',
  service: JobAddressesService
} as EntitySetConfig;
//#endregion