//#region ODataApiGen Imports
export * from './paplusApi/DataDomain/EFEntities/jobslistedm.complex';
export * from './paplusApi/DataDomain/EFEntities/addressedm.entity';
export * from './paplusApi/DataDomain/EFEntities/assetedm.entity';
export * from './paplusApi/DataDomain/EFEntities/assetmediaedm.entity';
export * from './paplusApi/DataDomain/EFEntities/assetparentassetedm.entity';
export * from './paplusApi/DataDomain/EFEntities/assettemplateedm.entity';
export * from './paplusApi/DataDomain/EFEntities/captureedm.entity';
export * from './paplusApi/DataDomain/EFEntities/closeoutpackageedm.entity';
export * from './paplusApi/DataDomain/EFEntities/deviceedm.entity';
export * from './paplusApi/DataDomain/EFEntities/iconedm.entity';
export * from './paplusApi/DataDomain/EFEntities/jobedm.entity';
export * from './paplusApi/DataDomain/EFEntities/jobaddressedm.entity';
export * from './paplusApi/DataDomain/EFEntities/jobassetedm.entity';
export * from './paplusApi/DataDomain/EFEntities/jobassetmediaedm.entity';
export * from './paplusApi/DataDomain/EFEntities/jobassetparentassetedm.entity';
export * from './paplusApi/DataDomain/EFEntities/jobassettemplateedm.entity';
export * from './paplusApi/DataDomain/EFEntities/jobtemplateedm.entity';
export * from './paplusApi/DataDomain/EFEntities/mediacontributoredm.entity';
export * from './paplusApi/DataDomain/EFEntities/mediacontributorjobassetedm.entity';
export * from './paplusApi/DataDomain/EFEntities/mediastatusedm.entity';
export * from './paplusApi/DataDomain/EFEntities/notificationtemplateedm.entity';
export * from './paplusApi/DataDomain/EFEntities/ouedm.entity';
export * from './paplusApi/DataDomain/EFEntities/ouaddressedm.entity';
export * from './paplusApi/DataDomain/EFEntities/oucloseoutpackageedm.entity';
export * from './paplusApi/DataDomain/EFEntities/outemplateedm.entity';
export * from './paplusApi/DataDomain/EFEntities/permissionedm.entity';
export * from './paplusApi/DataDomain/EFEntities/personedm.entity';
export * from './paplusApi/DataDomain/EFEntities/persondeviceedm.entity';
export * from './paplusApi/DataDomain/EFEntities/personjobedm.entity';
export * from './paplusApi/DataDomain/EFEntities/personmediacontributoredm.entity';
export * from './paplusApi/DataDomain/EFEntities/personmediastatusedm.entity';
export * from './paplusApi/DataDomain/EFEntities/personouroleedm.entity';
export * from './paplusApi/DataDomain/EFEntities/projectedm.entity';
export * from './paplusApi/DataDomain/EFEntities/projectpersonroleedm.entity';
export * from './paplusApi/DataDomain/EFEntities/projectprojectstatusedm.entity';
export * from './paplusApi/DataDomain/EFEntities/projectstatusedm.entity';
export * from './paplusApi/DataDomain/EFEntities/roleedm.entity';
export * from './paplusApi/DataDomain/EFEntities/rolepermissionedm.entity';
export * from './paplusApi/DataDomain/EFEntities/templateedm.entity';
export * from './paplusApi/DataDomain/EFEntities/jobslistedm.complex.config';
export * from './paplusApi/DataDomain/EFEntities/addressedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/assetedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/assetmediaedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/assetparentassetedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/assettemplateedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/captureedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/closeoutpackageedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/deviceedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/iconedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/jobedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/jobaddressedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/jobassetedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/jobassetmediaedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/jobassetparentassetedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/jobassettemplateedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/jobtemplateedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/mediacontributoredm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/mediacontributorjobassetedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/mediastatusedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/notificationtemplateedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/ouedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/ouaddressedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/oucloseoutpackageedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/outemplateedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/permissionedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/personedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/persondeviceedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/personjobedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/personmediacontributoredm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/personmediastatusedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/personouroleedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/projectedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/projectpersonroleedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/projectprojectstatusedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/projectstatusedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/roleedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/rolepermissionedm.entity.config';
export * from './paplusApi/DataDomain/EFEntities/templateedm.entity.config';
export * from './Default/container.service';
export * from './Default/addresses.service';
export * from './Default/assets.service';
export * from './Default/assetmedias.service';
export * from './Default/assetparentassets.service';
export * from './Default/assettemplates.service';
export * from './Default/captures.service';
export * from './Default/closeoutpackages.service';
export * from './Default/devices.service';
export * from './Default/icons.service';
export * from './Default/jobs.service';
export * from './Default/jobaddresses.service';
export * from './Default/jobassets.service';
export * from './Default/jobassetmedias.service';
export * from './Default/jobassetparentassets.service';
export * from './Default/jobassettemplates.service';
export * from './Default/jobtemplates.service';
export * from './Default/mediacontributors.service';
export * from './Default/mediacontributorjobassets.service';
export * from './Default/mediastatuses.service';
export * from './Default/notificationtemplates.service';
export * from './Default/ous.service';
export * from './Default/ouaddresses.service';
export * from './Default/oucloseoutpackages.service';
export * from './Default/outemplates.service';
export * from './Default/permissions.service';
export * from './Default/people.service';
export * from './Default/persondevices.service';
export * from './Default/personjobs.service';
export * from './Default/personmediacontributors.service';
export * from './Default/personmediastatuses.service';
export * from './Default/personouroles.service';
export * from './Default/projects.service';
export * from './Default/projectpersonroles.service';
export * from './Default/projectprojectstatuses.service';
export * from './Default/projectstatuses.service';
export * from './Default/roles.service';
export * from './Default/rolepermissions.service';
export * from './Default/templates.service';
export * from './Default/addresses.service.config';
export * from './Default/assets.service.config';
export * from './Default/assetmedias.service.config';
export * from './Default/assetparentassets.service.config';
export * from './Default/assettemplates.service.config';
export * from './Default/captures.service.config';
export * from './Default/closeoutpackages.service.config';
export * from './Default/devices.service.config';
export * from './Default/icons.service.config';
export * from './Default/jobs.service.config';
export * from './Default/jobaddresses.service.config';
export * from './Default/jobassets.service.config';
export * from './Default/jobassetmedias.service.config';
export * from './Default/jobassetparentassets.service.config';
export * from './Default/jobassettemplates.service.config';
export * from './Default/jobtemplates.service.config';
export * from './Default/mediacontributors.service.config';
export * from './Default/mediacontributorjobassets.service.config';
export * from './Default/mediastatuses.service.config';
export * from './Default/notificationtemplates.service.config';
export * from './Default/ous.service.config';
export * from './Default/ouaddresses.service.config';
export * from './Default/oucloseoutpackages.service.config';
export * from './Default/outemplates.service.config';
export * from './Default/permissions.service.config';
export * from './Default/people.service.config';
export * from './Default/persondevices.service.config';
export * from './Default/personjobs.service.config';
export * from './Default/personmediacontributors.service.config';
export * from './Default/personmediastatuses.service.config';
export * from './Default/personouroles.service.config';
export * from './Default/projects.service.config';
export * from './Default/projectpersonroles.service.config';
export * from './Default/projectprojectstatuses.service.config';
export * from './Default/projectstatuses.service.config';
export * from './Default/roles.service.config';
export * from './Default/rolepermissions.service.config';
export * from './Default/templates.service.config';
export * from './paplus-odata.config';
export * from './paplus-odata.module';
//#endregion