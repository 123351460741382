import { AuthenticationResult, AuthError, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { Constants } from "@azure/msal-common";

export class PAPlusPublicClientApplication extends PublicClientApplication implements IPublicClientApplication {
    /*override async handleRedirectPromise(hash ? : string): Promise <AuthenticationResult | null> {
        let response = this.redirectResponse.get(hash || Constants.EMPTY_STRING);
        if (typeof response !== "undefined") {
            response.then(x => {}).catch(error => {
                if (error instanceof AuthError) {
                    // AADB2C90091: The user has cancelled entering self-asserted information.
                    // removing the hash here to prevent Guard from blocking the state with the following message:
                    // Error - Guard - error while logging in, unable to activate
                    // this allows to remain application fully operational even if user cancelled Change Password or Impersonation
                    if (error.errorMessage.indexOf("AADB2C90091") > -1) {
                        if(hash) {
                            this.redirectResponse.delete(hash);
                        }
                    }
                }
            });
        }
        return super.handleRedirectPromise(hash)
    }*/
}