//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from './assetedm.entity';
import { JobAssetEdm } from './jobassetedm.entity';
//#endregion

export interface IconEdm {
  //#region ODataApiGen Properties
  iconGuid?: string;
  description: string;
  iconData: ArrayBuffer;
  assets?: AssetEdm[];
  jobAssets?: JobAssetEdm[];
  //#endregion
}