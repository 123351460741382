//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobEdm } from './jobedm.entity';
import { PersonEdm } from './personedm.entity';
//#endregion

export interface PersonJobEdm {
  //#region ODataApiGen Properties
  personJobGuid: string;
  personGuid?: string;
  jobGuid?: string;
  fromUtcDateTime: Date;
  toUtcDateTime?: Date;
  job?: JobEdm;
  person?: PersonEdm;
  //#endregion
}