import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetTemplateEdm } from '../paplusApi/DataDomain/EFEntities/assettemplateedm.entity';
import { JobTemplateEdm } from '../paplusApi/DataDomain/EFEntities/jobtemplateedm.entity';
import { OuTemplateEdm } from '../paplusApi/DataDomain/EFEntities/outemplateedm.entity';
import { PersonEdm } from '../paplusApi/DataDomain/EFEntities/personedm.entity';
import { TemplateEdm } from '../paplusApi/DataDomain/EFEntities/templateedm.entity';
//#endregion

@Injectable()
export class TemplatesService extends ODataEntitySetService<TemplateEdm> {
  constructor(client: ODataClient) {
    super(client, 'Templates', 'paplusApi.DataDomain.EFEntities.TemplateEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public assetTemplates(key: EntityKey<TemplateEdm>): ODataNavigationPropertyResource<AssetTemplateEdm> { 
    return this.entity(key).navigationProperty<AssetTemplateEdm>('assetTemplates'); 
  }
  public fetchAssetTemplates(key: EntityKey<TemplateEdm>, options?: ODataQueryArgumentsOptions<AssetTemplateEdm>) {
    return this.fetchNavigationProperty<AssetTemplateEdm>(
      this.assetTemplates(key), 
      'entities', options) as Observable<ODataEntities<AssetTemplateEdm>>;
  }
  public addAssetTemplateEdmToAssetTemplates(key: EntityKey<TemplateEdm>, target: ODataEntityResource<ODataEntities<AssetTemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.assetTemplates(key).reference()
      .add(target);
  }
  public removeAssetTemplateEdmFromAssetTemplates(key: EntityKey<TemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AssetTemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.assetTemplates(key).reference()
      .remove(target);
  }
  public jobTemplates(key: EntityKey<TemplateEdm>): ODataNavigationPropertyResource<JobTemplateEdm> { 
    return this.entity(key).navigationProperty<JobTemplateEdm>('jobTemplates'); 
  }
  public fetchJobTemplates(key: EntityKey<TemplateEdm>, options?: ODataQueryArgumentsOptions<JobTemplateEdm>) {
    return this.fetchNavigationProperty<JobTemplateEdm>(
      this.jobTemplates(key), 
      'entities', options) as Observable<ODataEntities<JobTemplateEdm>>;
  }
  public addJobTemplateEdmToJobTemplates(key: EntityKey<TemplateEdm>, target: ODataEntityResource<ODataEntities<JobTemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobTemplates(key).reference()
      .add(target);
  }
  public removeJobTemplateEdmFromJobTemplates(key: EntityKey<TemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<JobTemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobTemplates(key).reference()
      .remove(target);
  }
  public ouTemplates(key: EntityKey<TemplateEdm>): ODataNavigationPropertyResource<OuTemplateEdm> { 
    return this.entity(key).navigationProperty<OuTemplateEdm>('ouTemplates'); 
  }
  public fetchOuTemplates(key: EntityKey<TemplateEdm>, options?: ODataQueryArgumentsOptions<OuTemplateEdm>) {
    return this.fetchNavigationProperty<OuTemplateEdm>(
      this.ouTemplates(key), 
      'entities', options) as Observable<ODataEntities<OuTemplateEdm>>;
  }
  public addOuTemplateEdmToOuTemplates(key: EntityKey<TemplateEdm>, target: ODataEntityResource<ODataEntities<OuTemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ouTemplates(key).reference()
      .add(target);
  }
  public removeOuTemplateEdmFromOuTemplates(key: EntityKey<TemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OuTemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.ouTemplates(key).reference()
      .remove(target);
  }
  public person(key: EntityKey<TemplateEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<TemplateEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<TemplateEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<TemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  //#endregion
}
