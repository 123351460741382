//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonOuRolesService } from './personouroles.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PersonOuRolesServiceEntitySetConfig = {
  name: 'PersonOuRoles',
  entityType: 'paplusApi.DataDomain.EFEntities.PersonOuRoleEdm',
  service: PersonOuRolesService
} as EntitySetConfig;
//#endregion