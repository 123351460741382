//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AddressEdm } from './addressedm.entity';
import { JobEdm } from './jobedm.entity';
//#endregion

export interface JobAddressEdm {
  //#region ODataApiGen Properties
  jobGuid?: string;
  addressGuid?: string;
  addressType?: number;
  manuallyEntered?: boolean;
  fromDate: Date;
  toDate?: Date;
  address?: AddressEdm;
  job?: JobEdm;
  //#endregion
}