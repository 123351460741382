//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TemplateEdm } from './templateedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const TemplateEdmEntityConfig = {
  name: 'TemplateEdm',
  keys: [{name: 'templateGuid'}],
  fields: {
    templateGuid: {type: 'Edm.Guid', nullable: false},
    personGuid: {type: 'Edm.Guid'},
    description: {type: 'Edm.String', nullable: false, maxLength: -1},
    enabled: {type: 'Edm.Boolean'},
    person: {type: 'paplusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]},
    assetTemplates: {type: 'paplusApi.DataDomain.EFEntities.AssetTemplateEdm', collection: true, navigation: true},
    jobTemplates: {type: 'paplusApi.DataDomain.EFEntities.JobTemplateEdm', collection: true, navigation: true},
    ouTemplates: {type: 'paplusApi.DataDomain.EFEntities.OuTemplateEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<TemplateEdm>;
//#endregion