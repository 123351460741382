//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MediaContributorJobAssetEdm } from './mediacontributorjobassetedm.entity';
import { PersonMediaContributorEdm } from './personmediacontributoredm.entity';
//#endregion

export interface MediaContributorEdm {
  //#region ODataApiGen Properties
  mediaContributorGuid: string;
  emailAddress: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  mediaContributorJobAssets?: MediaContributorJobAssetEdm[];
  personMediaContributors?: PersonMediaContributorEdm[];
  //#endregion
}