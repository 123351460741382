import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CapturesService } from '@app/review-portal/services/captures.service';
import { CommonService } from '@app/shared/services/common.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-upload-capture',
  templateUrl: './upload-capture.component.html',
  styleUrls: ['./upload-capture.component.scss'],
})
export class UploadCaptureComponent implements OnInit {

  @Input() jobGuid: string;
  @Input() jobAssetGuid: string;
  @Input() jobAssetMediaGuid: string;

  public hasError = false;
  public buttonDisabled = false;

  //Document Params
  public documentField: FormControl = new FormControl('', Validators.required);
  public selectedFileName: string = '';
  private selectedFileToUpload: File | null = null;
  public readonly selectAFileInvitation: string = 'Please choose or drop a file.';
  public fileHasBeenDroppedIn: boolean = false;

  @ViewChild('documentToUpload') documentToUpload!: ElementRef;
  @ViewChild('documentDragZoneControl') documentDragZoneControl!: ElementRef<HTMLDivElement>;

  constructor(
    private capturesService: CapturesService,
    private commonService: CommonService,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    console.log('jobGuid:', this.jobGuid);
    console.log('jobAssetGuid:', this.jobAssetGuid);
    console.log('jobAssetMediaGuid:', this.jobAssetMediaGuid);
   }

  uploadClick() {
    if (this.documentField.valid || this.fileHasBeenDroppedIn) {
      
      this.hasError = false;
      this.buttonDisabled = true;
      this.uploadDocument();
    }
  }

  async uploadDocument() {

    let fileToUpload: File = this.selectedFileToUpload as File;
    if (fileToUpload) {
      this.capturesService.uploadCapture(this.jobGuid, this.jobAssetGuid, this.jobAssetMediaGuid, fileToUpload).subscribe({
        next: (res => {
          this.modalController.dismiss();
          this.commonService.openSnackBar('Capture uploaded successfully', 3000, 'success-snackbar');
        }),
        error: (error => {
          this.buttonDisabled = false;
          this.hasError = true;
        })
      });
    }
  }

  cancelClick() {
    this.modalController.dismiss();
  }


  //File Upload Functions
  onFileSelected(event: Event) {
    if (event && event.target) {
      if (((<HTMLInputElement>event.target).files as FileList).length === 0) {
        this.selectedFileName = '';
        this.selectedFileToUpload = null;
        this.fileHasBeenDroppedIn = false;
      } else {
        this.selectedFileToUpload = ((<HTMLInputElement>event.target).files as FileList)[0];
        this.selectedFileName = this.selectedFileToUpload.name;
      }
    }
  }

  allowDrop(ev: any) {
    ev.preventDefault();
  }

  somethingIsDraggedOver(event: any) {
    this.documentDragZoneControl.nativeElement.classList.add('file-dragged-over');
  }

  draggedOut(event: any) {
    this.documentDragZoneControl.nativeElement.classList.remove('file-dragged-over');
  }

  handleFileDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();

    this.documentDragZoneControl.nativeElement.classList.remove('file-dragged-over');

    var files = (event as DragEvent).dataTransfer?.files;

    if (files && files.length > 0) {
      this.selectedFileToUpload = files[0];
      this.selectedFileName = this.selectedFileToUpload.name;
    }

    this.fileHasBeenDroppedIn = true;
  }

  selectFileDialog() {
    this.documentToUpload.nativeElement.click();
  }
}
