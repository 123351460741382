//Set header property here
export const JOBS_HEADER: any[] = [
  {
    title: 'headerbar.jobs.admin',
    route: '/app/admin',
    icon: '',
    iconActive: '',
    visible: true,
  },
  {
    title: 'headerbar.jobs.reviewer',
    route: '/app/reviewportal',
    icon: '',
    iconActive: '',
    visible: true,
  },
  {
    title: 'headerbar.jobs.joblist',
    route: '/app/jobs',
    icon: '',
    iconActive: '',
    visible: true,
  },

]

export const PROJECTS_HEADER: any[] = [
  {
    title: 'headerbar.projects.admin',
    route: '/app/projects',
    icon: '',
    iconActive: '',
    visible: true,
  },


]

export const USERS_HEADER: any[] = [
  {
    title: 'headerbar.users.admin',
    route: '/app/admin',
    icon: '',
    iconActive: '',
    visible: true,
  },

]

export const CUSTOMERS_HEADER: any[] = [
  {
    title: 'headerbar.customers.admin',
    route: '/app/admin',
    icon: '',
    iconActive: '',
    visible: true,
  },

]
