//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonDevicesService } from './persondevices.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PersonDevicesServiceEntitySetConfig = {
  name: 'PersonDevices',
  entityType: 'paplusApi.DataDomain.EFEntities.PersonDeviceEdm',
  service: PersonDevicesService
} as EntitySetConfig;
//#endregion