//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProjectProjectStatusEdm } from './projectprojectstatusedm.entity';
//#endregion

export interface ProjectStatusEdm {
  //#region ODataApiGen Properties
  projectStatusGuid: string;
  description: string;
  projectProjectStatuses?: ProjectProjectStatusEdm[];
  //#endregion
}