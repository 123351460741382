export const environment = {
  production: false,
  URL: 'https://portal.paplus.dev.rgsit.cloud',
  API: 'https://api.paplus.dev.rgsit.cloud/odata',
  B2CDomain: 'paplusdev',
  B2CTenantId: '31806fdc-be9d-4e64-88df-f9579aa99fbd',
  ClientId: '0e013de8-0fad-4b64-91ce-97f53328d206',
  BackendAppIdentifier: 'dev-api',
  googleMapsApiKey: 'AIzaSyAOvDCpP7qwiEBws8ei-Efyh96d3aoyrnc'
};
