<ion-header [translucent]="true">
  <ion-toolbar color="primary" class="padding">

    <ion-button slot="start" (click)="toggleSideMenu()" color="secondary">
      <ion-icon slot="icon-only" [name]="isMenuOpen? 'close':'menu'"></ion-icon>
    </ion-button>


    <ion-img class="logo" slot="start" src="assets/img/PA+-Logo-Part-White.png" alt="Photo Audit Plus"></ion-img>
    <!--<ion-button color="secondary" slot="end" expand="block" (click)="logout()">Log out</ion-button>-->

    <ion-buttons class="paddingLeft" slot="start" fill="clear">

      <!--<ion-button routerLink="/app/jobs">
        Jobs
      </ion-button>-->

      <div *ngFor="let m of headerMenus">
        <ion-button [routerLink]="[m.route]" *ngIf="m.visible">
          {{m.title | translate}}
        </ion-button>
      </div>

    </ion-buttons>



    <ion-buttons slot="end" *ngIf="userIsLoggedIn" class="paddingRight">

      <ion-button id="present-alert">Language</ion-button>
      <ion-alert trigger="present-alert"
                 header="Default Language"
                 [buttons]="alertButtons"
                 [inputs]="alertInputs"></ion-alert>


      <ion-button fill="clear">
        <ion-img src="assets/img/icon-user.png"></ion-img>
      </ion-button>
      <ion-button color="secondary" fill="clear" size="small" (click)="logout()">
        <ion-img src="assets/img/icon-logout.png"></ion-img>
      </ion-button>
    </ion-buttons>


  </ion-toolbar>
</ion-header>

