<ion-card>
  <ion-toolbar>
    <ion-card-title slot="start" class="header">Upload New Capture</ion-card-title>
    <ion-button (click)="cancelClick()" color="white" slot="end" class="modal-cancel">
      Cancel
    </ion-button>
    <ion-button (click)="uploadClick()" color="secondary" slot="end" class="modal-confirm">
      Upload
    </ion-button>
  </ion-toolbar>

  <div class="upload-new-document">
    <div #documentDragZoneControl class="file-uploader-control" (dragover)="allowDrop($event);"
      (drop)="handleFileDrop($event);" (dragover)="somethingIsDraggedOver($event);" (dragleave)="draggedOut($event);">
      <div class="file-uploading-name">
        <span>{{ selectedFileName === '' ? selectAFileInvitation : selectedFileName }}</span>
      </div>
      <div class="file-uploading-upload-button">
        <a id="selectFileToUploadButton" (click)="selectFileDialog()">
          <mat-icon inline="true" class="upload-cloud-icon">backup</mat-icon>Browse the
          File</a>
      </div>
    </div>
    <div class="invisible-input-file-tag">
      <mat-error class="mandatory-field"
        *ngIf="!fileHasBeenDroppedIn && documentField.dirty && documentField.hasError('required')">
        Document to upload is missing.
      </mat-error>
      <input type="file" id="documentToUpload" [formControl]="documentField" #documentToUpload
        (change)="onFileSelected($event)" accept=".jpg, .jpeg, .png" />
    </div>
  </div>
  <div *ngIf="hasError" class="error-message" >
    There was an error with the file upload.
</div>
</ion-card>