//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { IconEdm } from './iconedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const IconEdmEntityConfig = {
  name: 'IconEdm',
  keys: [{name: 'iconGuid'}],
  fields: {
    iconGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String', nullable: false},
    iconData: {type: 'Edm.Binary', nullable: false},
    assets: {type: 'paplusApi.DataDomain.EFEntities.AssetEdm', collection: true, navigation: true},
    jobAssets: {type: 'paplusApi.DataDomain.EFEntities.JobAssetEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<IconEdm>;
//#endregion