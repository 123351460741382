//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonDeviceEdm } from './persondeviceedm.entity';
//#endregion

export interface DeviceEdm {
  //#region ODataApiGen Properties
  deviceGuid: string;
  imei?: string;
  enabled?: boolean;
  description?: string;
  hostHeader?: string;
  personDevices?: PersonDeviceEdm[];
  //#endregion
}