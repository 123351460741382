import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AddressEdm } from '../paplusApi/DataDomain/EFEntities/addressedm.entity';
import { JobEdm } from '../paplusApi/DataDomain/EFEntities/jobedm.entity';
import { JobAddressEdm } from '../paplusApi/DataDomain/EFEntities/jobaddressedm.entity';
//#endregion

@Injectable()
export class JobAddressesService extends ODataEntitySetService<JobAddressEdm> {
  constructor(client: ODataClient) {
    super(client, 'JobAddresses', 'paplusApi.DataDomain.EFEntities.JobAddressEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public address(key: EntityKey<JobAddressEdm>): ODataNavigationPropertyResource<AddressEdm> { 
    return this.entity(key).navigationProperty<AddressEdm>('address'); 
  }
  public fetchAddress(key: EntityKey<JobAddressEdm>, options?: ODataQueryArgumentsOptions<AddressEdm>) {
    return this.fetchNavigationProperty<AddressEdm>(
      this.address(key), 
      'entity', options) as Observable<ODataEntity<AddressEdm>>;
  }
  public setAddressEdmAsAddress(key: EntityKey<JobAddressEdm>, target: ODataEntityResource<ODataEntity<AddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.address(key).reference()
      .set(target, {etag});
  }
  public unsetAddressEdmAsAddress(key: EntityKey<JobAddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.address(key).reference()
      .unset({etag});
  }
  public job(key: EntityKey<JobAddressEdm>): ODataNavigationPropertyResource<JobEdm> { 
    return this.entity(key).navigationProperty<JobEdm>('job'); 
  }
  public fetchJob(key: EntityKey<JobAddressEdm>, options?: ODataQueryArgumentsOptions<JobEdm>) {
    return this.fetchNavigationProperty<JobEdm>(
      this.job(key), 
      'entity', options) as Observable<ODataEntity<JobEdm>>;
  }
  public setJobEdmAsJob(key: EntityKey<JobAddressEdm>, target: ODataEntityResource<ODataEntity<JobEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.job(key).reference()
      .set(target, {etag});
  }
  public unsetJobEdmAsJob(key: EntityKey<JobAddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<JobEdm>>, etag?: string} = {}): Observable<any> {
    return this.job(key).reference()
      .unset({etag});
  }
  //#endregion
}
