//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetParentAssetsService } from './assetparentassets.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AssetParentAssetsServiceEntitySetConfig = {
  name: 'AssetParentAssets',
  entityType: 'paplusApi.DataDomain.EFEntities.AssetParentAssetEdm',
  service: AssetParentAssetsService
} as EntitySetConfig;
//#endregion