//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PermissionEdm } from './permissionedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PermissionEdmEntityConfig = {
  name: 'PermissionEdm',
  keys: [{name: 'permissionGuid'}],
  fields: {
    permissionGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String'},
    tag: {type: 'Edm.String'},
    active: {type: 'Edm.Boolean'},
    rolePermissions: {type: 'paplusApi.DataDomain.EFEntities.RolePermissionEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<PermissionEdm>;
//#endregion