import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CloseoutPackageEdm } from '../paplusApi/DataDomain/EFEntities/closeoutpackageedm.entity';
import { OuCloseoutPackageEdm } from '../paplusApi/DataDomain/EFEntities/oucloseoutpackageedm.entity';
//#endregion

@Injectable()
export class CloseoutPackagesService extends ODataEntitySetService<CloseoutPackageEdm> {
  constructor(client: ODataClient) {
    super(client, 'CloseoutPackages', 'paplusApi.DataDomain.EFEntities.CloseoutPackageEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public ouCloseoutPackages(key: EntityKey<CloseoutPackageEdm>): ODataNavigationPropertyResource<OuCloseoutPackageEdm> { 
    return this.entity(key).navigationProperty<OuCloseoutPackageEdm>('ouCloseoutPackages'); 
  }
  public fetchOuCloseoutPackages(key: EntityKey<CloseoutPackageEdm>, options?: ODataQueryArgumentsOptions<OuCloseoutPackageEdm>) {
    return this.fetchNavigationProperty<OuCloseoutPackageEdm>(
      this.ouCloseoutPackages(key), 
      'entities', options) as Observable<ODataEntities<OuCloseoutPackageEdm>>;
  }
  public addOuCloseoutPackageEdmToOuCloseoutPackages(key: EntityKey<CloseoutPackageEdm>, target: ODataEntityResource<ODataEntities<OuCloseoutPackageEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ouCloseoutPackages(key).reference()
      .add(target);
  }
  public removeOuCloseoutPackageEdmFromOuCloseoutPackages(key: EntityKey<CloseoutPackageEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OuCloseoutPackageEdm>>, etag?: string} = {}): Observable<any> {
    return this.ouCloseoutPackages(key).reference()
      .remove(target);
  }
  //#endregion
}
