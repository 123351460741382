import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {

  public alertButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
      },
    },
    {
      text: 'OK',
      role: 'confirm',
      handler: () => {
      },
    },
  ];

	message: string = "Are you sure?"
	confirmButtonText = "Yes"
	cancelButtonText = "Cancel"
	hideCancel: false;

  public isAlertOpen: true;

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialogRef: MatDialogRef<ConfirmationDialogComponent>
	) {
		if (data) {
			this.message = data.message || this.message;
			if (data.buttonText) {
        this.alertButtons[1].text = data.buttonText.ok || this.confirmButtonText;
        this.alertButtons[0].text = data.buttonText.cancel || this.cancelButtonText;
			}
			if (data.hideCancel) {
				this.hideCancel = data.hideCancel;
        this.alertButtons.splice(this.alertButtons.findIndex((el) => el.role === 'cancel'), 1);
			}
		}
	}

	onConfirmClick(): void {
		this.dialogRef.close(true);
	}

  setResult(ev) {    
		this.dialogRef.close(ev.detail.role === 'confirm');
  }
}
