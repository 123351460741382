//#region ODataApiGen ODataImports
import {
  NgModule
} from '@angular/core';//#endregion

//#region ODataApiGen Imports
import { ContainerService } from './Default/container.service';
import { AddressesService } from './Default/addresses.service';
import { AssetsService } from './Default/assets.service';
import { AssetMediasService } from './Default/assetmedias.service';
import { AssetParentAssetsService } from './Default/assetparentassets.service';
import { AssetTemplatesService } from './Default/assettemplates.service';
import { CapturesService } from './Default/captures.service';
import { CloseoutPackagesService } from './Default/closeoutpackages.service';
import { DevicesService } from './Default/devices.service';
import { IconsService } from './Default/icons.service';
import { JobsService } from './Default/jobs.service';
import { JobAddressesService } from './Default/jobaddresses.service';
import { JobAssetsService } from './Default/jobassets.service';
import { JobAssetMediasService } from './Default/jobassetmedias.service';
import { JobAssetParentAssetsService } from './Default/jobassetparentassets.service';
import { JobAssetTemplatesService } from './Default/jobassettemplates.service';
import { JobTemplatesService } from './Default/jobtemplates.service';
import { MediaContributorsService } from './Default/mediacontributors.service';
import { MediaContributorJobAssetsService } from './Default/mediacontributorjobassets.service';
import { MediaStatusesService } from './Default/mediastatuses.service';
import { NotificationTemplatesService } from './Default/notificationtemplates.service';
import { OusService } from './Default/ous.service';
import { OuAddressesService } from './Default/ouaddresses.service';
import { OuCloseoutPackagesService } from './Default/oucloseoutpackages.service';
import { OuTemplatesService } from './Default/outemplates.service';
import { PermissionsService } from './Default/permissions.service';
import { PeopleService } from './Default/people.service';
import { PersonDevicesService } from './Default/persondevices.service';
import { PersonJobsService } from './Default/personjobs.service';
import { PersonMediaContributorsService } from './Default/personmediacontributors.service';
import { PersonMediaStatusesService } from './Default/personmediastatuses.service';
import { PersonOuRolesService } from './Default/personouroles.service';
import { ProjectsService } from './Default/projects.service';
import { ProjectPersonRolesService } from './Default/projectpersonroles.service';
import { ProjectProjectStatusesService } from './Default/projectprojectstatuses.service';
import { ProjectStatusesService } from './Default/projectstatuses.service';
import { RolesService } from './Default/roles.service';
import { RolePermissionsService } from './Default/rolepermissions.service';
import { TemplatesService } from './Default/templates.service';
//#endregion

@NgModule({
  providers: [
//#region ODataApiGen Providers
    ContainerService,
    AddressesService,
    AssetsService,
    AssetMediasService,
    AssetParentAssetsService,
    AssetTemplatesService,
    CapturesService,
    CloseoutPackagesService,
    DevicesService,
    IconsService,
    JobsService,
    JobAddressesService,
    JobAssetsService,
    JobAssetMediasService,
    JobAssetParentAssetsService,
    JobAssetTemplatesService,
    JobTemplatesService,
    MediaContributorsService,
    MediaContributorJobAssetsService,
    MediaStatusesService,
    NotificationTemplatesService,
    OusService,
    OuAddressesService,
    OuCloseoutPackagesService,
    OuTemplatesService,
    PermissionsService,
    PeopleService,
    PersonDevicesService,
    PersonJobsService,
    PersonMediaContributorsService,
    PersonMediaStatusesService,
    PersonOuRolesService,
    ProjectsService,
    ProjectPersonRolesService,
    ProjectProjectStatusesService,
    ProjectStatusesService,
    RolesService,
    RolePermissionsService,
    TemplatesService//#endregion
  ]
})
export class PAPlusOdataModule { }