<!--<div class="confirmation-dialog-container">
	<mat-dialog-content>
		<span [innerHTML]="message"></span>
	</mat-dialog-content>
	<mat-dialog-actions style="float:right">
		<button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
		<button *ngIf="!hideCancel" mat-raised-button color="warn" mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
	</mat-dialog-actions>
</div>-->

<ion-alert
  [isOpen]="isAlertOpen"
  [header]="message"
  [buttons]="alertButtons"
  (didDismiss)="setResult($event)"
></ion-alert>