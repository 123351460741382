//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CaptureEdm } from './captureedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CaptureEdmEntityConfig = {
  name: 'CaptureEdm',
  keys: [{name: 'captureGuid'}],
  fields: {
    captureGuid: {type: 'Edm.Guid', nullable: false},
    jobAssetMediaGuid: {type: 'Edm.Guid'},
    createUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    notes: {type: 'Edm.String', maxLength: -1},
    mediaSource: {type: 'Edm.Int32'},
    filePath: {type: 'Edm.String', nullable: false},
    nativeFileName: {type: 'Edm.String'},
    jobAssetMedia: {type: 'paplusApi.DataDomain.EFEntities.JobAssetMediaEdm', navigation: true, referentials: [{property: 'jobAssetMediaGuid', referencedProperty: 'jobAssetMediaGuid'}]}
  }
} as StructuredTypeConfig<CaptureEdm>;
//#endregion