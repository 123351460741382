//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MediaContributorEdm } from './mediacontributoredm.entity';
import { PersonEdm } from './personedm.entity';
//#endregion

export interface PersonMediaContributorEdm {
  //#region ODataApiGen Properties
  personMediaContributorGuid: string;
  personGuid?: string;
  mediaContributorGuid?: string;
  mediaContributor?: MediaContributorEdm;
  person?: PersonEdm;
  //#endregion
}