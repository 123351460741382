//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OusService } from './ous.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const OusServiceEntitySetConfig = {
  name: 'Ous',
  entityType: 'paplusApi.DataDomain.EFEntities.OuEdm',
  service: OusService
} as EntitySetConfig;
//#endregion