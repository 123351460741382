//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetsService } from './assets.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AssetsServiceEntitySetConfig = {
  name: 'Assets',
  entityType: 'paplusApi.DataDomain.EFEntities.AssetEdm',
  service: AssetsService
} as EntitySetConfig;
//#endregion