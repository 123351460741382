//#region ODataApiGen ODataImports
import {
  EntityContainerConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AddressesServiceEntitySetConfig } from './addresses.service.config';
import { AssetsServiceEntitySetConfig } from './assets.service.config';
import { AssetMediasServiceEntitySetConfig } from './assetmedias.service.config';
import { AssetParentAssetsServiceEntitySetConfig } from './assetparentassets.service.config';
import { AssetTemplatesServiceEntitySetConfig } from './assettemplates.service.config';
import { CapturesServiceEntitySetConfig } from './captures.service.config';
import { CloseoutPackagesServiceEntitySetConfig } from './closeoutpackages.service.config';
import { DevicesServiceEntitySetConfig } from './devices.service.config';
import { IconsServiceEntitySetConfig } from './icons.service.config';
import { JobsServiceEntitySetConfig } from './jobs.service.config';
import { JobAddressesServiceEntitySetConfig } from './jobaddresses.service.config';
import { JobAssetsServiceEntitySetConfig } from './jobassets.service.config';
import { JobAssetMediasServiceEntitySetConfig } from './jobassetmedias.service.config';
import { JobAssetParentAssetsServiceEntitySetConfig } from './jobassetparentassets.service.config';
import { JobAssetTemplatesServiceEntitySetConfig } from './jobassettemplates.service.config';
import { JobTemplatesServiceEntitySetConfig } from './jobtemplates.service.config';
import { MediaContributorsServiceEntitySetConfig } from './mediacontributors.service.config';
import { MediaContributorJobAssetsServiceEntitySetConfig } from './mediacontributorjobassets.service.config';
import { MediaStatusesServiceEntitySetConfig } from './mediastatuses.service.config';
import { NotificationTemplatesServiceEntitySetConfig } from './notificationtemplates.service.config';
import { OusServiceEntitySetConfig } from './ous.service.config';
import { OuAddressesServiceEntitySetConfig } from './ouaddresses.service.config';
import { OuCloseoutPackagesServiceEntitySetConfig } from './oucloseoutpackages.service.config';
import { OuTemplatesServiceEntitySetConfig } from './outemplates.service.config';
import { PermissionsServiceEntitySetConfig } from './permissions.service.config';
import { PeopleServiceEntitySetConfig } from './people.service.config';
import { PersonDevicesServiceEntitySetConfig } from './persondevices.service.config';
import { PersonJobsServiceEntitySetConfig } from './personjobs.service.config';
import { PersonMediaContributorsServiceEntitySetConfig } from './personmediacontributors.service.config';
import { PersonMediaStatusesServiceEntitySetConfig } from './personmediastatuses.service.config';
import { PersonOuRolesServiceEntitySetConfig } from './personouroles.service.config';
import { ProjectsServiceEntitySetConfig } from './projects.service.config';
import { ProjectPersonRolesServiceEntitySetConfig } from './projectpersonroles.service.config';
import { ProjectProjectStatusesServiceEntitySetConfig } from './projectprojectstatuses.service.config';
import { ProjectStatusesServiceEntitySetConfig } from './projectstatuses.service.config';
import { RolesServiceEntitySetConfig } from './roles.service.config';
import { RolePermissionsServiceEntitySetConfig } from './rolepermissions.service.config';
import { TemplatesServiceEntitySetConfig } from './templates.service.config';
//#endregion

//#region ODataApiGen EntityContainerConfig
export const ContainerContainer = {
  name: 'Container',
  entitySets: [
    AddressesServiceEntitySetConfig,
    AssetsServiceEntitySetConfig,
    AssetMediasServiceEntitySetConfig,
    AssetParentAssetsServiceEntitySetConfig,
    AssetTemplatesServiceEntitySetConfig,
    CapturesServiceEntitySetConfig,
    CloseoutPackagesServiceEntitySetConfig,
    DevicesServiceEntitySetConfig,
    IconsServiceEntitySetConfig,
    JobsServiceEntitySetConfig,
    JobAddressesServiceEntitySetConfig,
    JobAssetsServiceEntitySetConfig,
    JobAssetMediasServiceEntitySetConfig,
    JobAssetParentAssetsServiceEntitySetConfig,
    JobAssetTemplatesServiceEntitySetConfig,
    JobTemplatesServiceEntitySetConfig,
    MediaContributorsServiceEntitySetConfig,
    MediaContributorJobAssetsServiceEntitySetConfig,
    MediaStatusesServiceEntitySetConfig,
    NotificationTemplatesServiceEntitySetConfig,
    OusServiceEntitySetConfig,
    OuAddressesServiceEntitySetConfig,
    OuCloseoutPackagesServiceEntitySetConfig,
    OuTemplatesServiceEntitySetConfig,
    PermissionsServiceEntitySetConfig,
    PeopleServiceEntitySetConfig,
    PersonDevicesServiceEntitySetConfig,
    PersonJobsServiceEntitySetConfig,
    PersonMediaContributorsServiceEntitySetConfig,
    PersonMediaStatusesServiceEntitySetConfig,
    PersonOuRolesServiceEntitySetConfig,
    ProjectsServiceEntitySetConfig,
    ProjectPersonRolesServiceEntitySetConfig,
    ProjectProjectStatusesServiceEntitySetConfig,
    ProjectStatusesServiceEntitySetConfig,
    RolesServiceEntitySetConfig,
    RolePermissionsServiceEntitySetConfig,
    TemplatesServiceEntitySetConfig
  ]
} as EntityContainerConfig;
//#endregion