//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProjectEdm } from './projectedm.entity';
import { ProjectStatusEdm } from './projectstatusedm.entity';
//#endregion

export interface ProjectProjectStatusEdm {
  //#region ODataApiGen Properties
  projectProjectStatusGuid: string;
  projectStatusGuid?: string;
  projectGuid?: string;
  fromUtcDateTime: Date;
  toUtcDateTime?: Date;
  project?: ProjectEdm;
  projectStatus?: ProjectStatusEdm;
  //#endregion
}