//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProjectEdm } from './projectedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ProjectEdmEntityConfig = {
  name: 'ProjectEdm',
  keys: [{name: 'projectGuid'}],
  fields: {
    projectGuid: {type: 'Edm.Guid', nullable: false},
    ouguid: {type: 'Edm.Guid'},
    description: {type: 'Edm.String', nullable: false, maxLength: -1},
    code: {type: 'Edm.String', nullable: false},
    externalCode: {type: 'Edm.String'},
    photoExportResolution: {type: 'Edm.String'},
    importTemplatesSerialisedValue: {type: 'Edm.String', maxLength: -1},
    notes: {type: 'Edm.String', maxLength: -1},
    ou: {type: 'paplusApi.DataDomain.EFEntities.OuEdm', navigation: true, referentials: [{property: 'ouguid', referencedProperty: 'ouguid'}]},
    jobs: {type: 'paplusApi.DataDomain.EFEntities.JobEdm', collection: true, navigation: true},
    projectPersonRoles: {type: 'paplusApi.DataDomain.EFEntities.ProjectPersonRoleEdm', collection: true, navigation: true},
    projectProjectStatuses: {type: 'paplusApi.DataDomain.EFEntities.ProjectProjectStatusEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<ProjectEdm>;
//#endregion