//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CaptureEdm } from './captureedm.entity';
import { JobAssetEdm } from './jobassetedm.entity';
import { PersonMediaStatusEdm } from './personmediastatusedm.entity';
//#endregion

export interface JobAssetMediaEdm {
  //#region ODataApiGen Properties
  jobAssetMediaGuid: string;
  jobAssetGuid?: string;
  description?: string;
  abbreviatedDescription: string;
  fileNamePart: string;
  jobAsset?: JobAssetEdm;
  captures?: CaptureEdm[];
  personMediaStatuses?: PersonMediaStatusEdm[];
  //#endregion
}