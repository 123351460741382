//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAddressEdm } from './jobaddressedm.entity';
import { JobTemplateEdm } from './jobtemplateedm.entity';
import { OuEdm } from './ouedm.entity';
import { PersonJobEdm } from './personjobedm.entity';
import { ProjectEdm } from './projectedm.entity';
//#endregion

export interface JobEdm {
  //#region ODataApiGen Properties
  jobGuid?: string;
  projectGuid?: string;
  ouGuid?: string;
  description?: string;
  lat?: number;
  lng?: number;
  what3Words?: string;
  siteCode?: string;
  customerJobCode?: string;
  notes?: string;
  status?: number;
  siteName?: string;
  statusDate?: Date;
  ou?: OuEdm;
  project?: ProjectEdm;
  jobAddresses?: JobAddressEdm[];
  jobTemplates?: JobTemplateEdm[];
  personJobs?: PersonJobEdm[];
  //#endregion
}
