import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { IconEdm } from '../paplusApi/DataDomain/EFEntities/iconedm.entity';
import { JobAssetEdm } from '../paplusApi/DataDomain/EFEntities/jobassetedm.entity';
import { JobAssetMediaEdm } from '../paplusApi/DataDomain/EFEntities/jobassetmediaedm.entity';
import { JobAssetParentAssetEdm } from '../paplusApi/DataDomain/EFEntities/jobassetparentassetedm.entity';
import { JobAssetTemplateEdm } from '../paplusApi/DataDomain/EFEntities/jobassettemplateedm.entity';
import { MediaContributorJobAssetEdm } from '../paplusApi/DataDomain/EFEntities/mediacontributorjobassetedm.entity';
//#endregion

@Injectable()
export class JobAssetsService extends ODataEntitySetService<JobAssetEdm> {
  constructor(client: ODataClient) {
    super(client, 'JobAssets', 'paplusApi.DataDomain.EFEntities.JobAssetEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public icon(key: EntityKey<JobAssetEdm>): ODataNavigationPropertyResource<IconEdm> { 
    return this.entity(key).navigationProperty<IconEdm>('icon'); 
  }
  public fetchIcon(key: EntityKey<JobAssetEdm>, options?: ODataQueryArgumentsOptions<IconEdm>) {
    return this.fetchNavigationProperty<IconEdm>(
      this.icon(key), 
      'entity', options) as Observable<ODataEntity<IconEdm>>;
  }
  public setIconEdmAsIcon(key: EntityKey<JobAssetEdm>, target: ODataEntityResource<ODataEntity<IconEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.icon(key).reference()
      .set(target, {etag});
  }
  public unsetIconEdmAsIcon(key: EntityKey<JobAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<IconEdm>>, etag?: string} = {}): Observable<any> {
    return this.icon(key).reference()
      .unset({etag});
  }
  public jobAssetMedias(key: EntityKey<JobAssetEdm>): ODataNavigationPropertyResource<JobAssetMediaEdm> { 
    return this.entity(key).navigationProperty<JobAssetMediaEdm>('jobAssetMedias'); 
  }
  public fetchJobAssetMedias(key: EntityKey<JobAssetEdm>, options?: ODataQueryArgumentsOptions<JobAssetMediaEdm>) {
    return this.fetchNavigationProperty<JobAssetMediaEdm>(
      this.jobAssetMedias(key), 
      'entities', options) as Observable<ODataEntities<JobAssetMediaEdm>>;
  }
  public addJobAssetMediaEdmToJobAssetMedias(key: EntityKey<JobAssetEdm>, target: ODataEntityResource<ODataEntities<JobAssetMediaEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobAssetMedias(key).reference()
      .add(target);
  }
  public removeJobAssetMediaEdmFromJobAssetMedias(key: EntityKey<JobAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<JobAssetMediaEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobAssetMedias(key).reference()
      .remove(target);
  }
  public jobAssetParentAssets(key: EntityKey<JobAssetEdm>): ODataNavigationPropertyResource<JobAssetParentAssetEdm> { 
    return this.entity(key).navigationProperty<JobAssetParentAssetEdm>('jobAssetParentAssets'); 
  }
  public fetchJobAssetParentAssets(key: EntityKey<JobAssetEdm>, options?: ODataQueryArgumentsOptions<JobAssetParentAssetEdm>) {
    return this.fetchNavigationProperty<JobAssetParentAssetEdm>(
      this.jobAssetParentAssets(key), 
      'entities', options) as Observable<ODataEntities<JobAssetParentAssetEdm>>;
  }
  public addJobAssetParentAssetEdmToJobAssetParentAssets(key: EntityKey<JobAssetEdm>, target: ODataEntityResource<ODataEntities<JobAssetParentAssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobAssetParentAssets(key).reference()
      .add(target);
  }
  public removeJobAssetParentAssetEdmFromJobAssetParentAssets(key: EntityKey<JobAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<JobAssetParentAssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobAssetParentAssets(key).reference()
      .remove(target);
  }
  public jobAssetTemplates(key: EntityKey<JobAssetEdm>): ODataNavigationPropertyResource<JobAssetTemplateEdm> { 
    return this.entity(key).navigationProperty<JobAssetTemplateEdm>('jobAssetTemplates'); 
  }
  public fetchJobAssetTemplates(key: EntityKey<JobAssetEdm>, options?: ODataQueryArgumentsOptions<JobAssetTemplateEdm>) {
    return this.fetchNavigationProperty<JobAssetTemplateEdm>(
      this.jobAssetTemplates(key), 
      'entities', options) as Observable<ODataEntities<JobAssetTemplateEdm>>;
  }
  public addJobAssetTemplateEdmToJobAssetTemplates(key: EntityKey<JobAssetEdm>, target: ODataEntityResource<ODataEntities<JobAssetTemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobAssetTemplates(key).reference()
      .add(target);
  }
  public removeJobAssetTemplateEdmFromJobAssetTemplates(key: EntityKey<JobAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<JobAssetTemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobAssetTemplates(key).reference()
      .remove(target);
  }
  public jobParentAssetGUIDJobAsset(key: EntityKey<JobAssetEdm>): ODataNavigationPropertyResource<JobAssetEdm> { 
    return this.entity(key).navigationProperty<JobAssetEdm>('jobParentAssetGUIDJobAsset'); 
  }
  public fetchJobParentAssetGUIDJobAsset(key: EntityKey<JobAssetEdm>, options?: ODataQueryArgumentsOptions<JobAssetEdm>) {
    return this.fetchNavigationProperty<JobAssetEdm>(
      this.jobParentAssetGUIDJobAsset(key), 
      'entity', options) as Observable<ODataEntity<JobAssetEdm>>;
  }
  public setJobAssetEdmAsJobParentAssetGUIDJobAsset(key: EntityKey<JobAssetEdm>, target: ODataEntityResource<ODataEntity<JobAssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobParentAssetGUIDJobAsset(key).reference()
      .set(target, {etag});
  }
  public unsetJobAssetEdmAsJobParentAssetGUIDJobAsset(key: EntityKey<JobAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<JobAssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobParentAssetGUIDJobAsset(key).reference()
      .unset({etag});
  }
  public jobParentAssetGUIDJobAssetParentAssets(key: EntityKey<JobAssetEdm>): ODataNavigationPropertyResource<JobAssetParentAssetEdm> { 
    return this.entity(key).navigationProperty<JobAssetParentAssetEdm>('jobParentAssetGUIDJobAssetParentAssets'); 
  }
  public fetchJobParentAssetGUIDJobAssetParentAssets(key: EntityKey<JobAssetEdm>, options?: ODataQueryArgumentsOptions<JobAssetParentAssetEdm>) {
    return this.fetchNavigationProperty<JobAssetParentAssetEdm>(
      this.jobParentAssetGUIDJobAssetParentAssets(key), 
      'entities', options) as Observable<ODataEntities<JobAssetParentAssetEdm>>;
  }
  public addJobAssetParentAssetEdmToJobParentAssetGUIDJobAssetParentAssets(key: EntityKey<JobAssetEdm>, target: ODataEntityResource<ODataEntities<JobAssetParentAssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobParentAssetGUIDJobAssetParentAssets(key).reference()
      .add(target);
  }
  public removeJobAssetParentAssetEdmFromJobParentAssetGUIDJobAssetParentAssets(key: EntityKey<JobAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<JobAssetParentAssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobParentAssetGUIDJobAssetParentAssets(key).reference()
      .remove(target);
  }
  public jobParentAssetGUIDJobAssets(key: EntityKey<JobAssetEdm>): ODataNavigationPropertyResource<JobAssetEdm> { 
    return this.entity(key).navigationProperty<JobAssetEdm>('jobParentAssetGUIDJobAssets'); 
  }
  public fetchJobParentAssetGUIDJobAssets(key: EntityKey<JobAssetEdm>, options?: ODataQueryArgumentsOptions<JobAssetEdm>) {
    return this.fetchNavigationProperty<JobAssetEdm>(
      this.jobParentAssetGUIDJobAssets(key), 
      'entities', options) as Observable<ODataEntities<JobAssetEdm>>;
  }
  public addJobAssetEdmToJobParentAssetGUIDJobAssets(key: EntityKey<JobAssetEdm>, target: ODataEntityResource<ODataEntities<JobAssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobParentAssetGUIDJobAssets(key).reference()
      .add(target);
  }
  public removeJobAssetEdmFromJobParentAssetGUIDJobAssets(key: EntityKey<JobAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<JobAssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobParentAssetGUIDJobAssets(key).reference()
      .remove(target);
  }
  public mediaContributorJobAssets(key: EntityKey<JobAssetEdm>): ODataNavigationPropertyResource<MediaContributorJobAssetEdm> { 
    return this.entity(key).navigationProperty<MediaContributorJobAssetEdm>('mediaContributorJobAssets'); 
  }
  public fetchMediaContributorJobAssets(key: EntityKey<JobAssetEdm>, options?: ODataQueryArgumentsOptions<MediaContributorJobAssetEdm>) {
    return this.fetchNavigationProperty<MediaContributorJobAssetEdm>(
      this.mediaContributorJobAssets(key), 
      'entities', options) as Observable<ODataEntities<MediaContributorJobAssetEdm>>;
  }
  public addMediaContributorJobAssetEdmToMediaContributorJobAssets(key: EntityKey<JobAssetEdm>, target: ODataEntityResource<ODataEntities<MediaContributorJobAssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.mediaContributorJobAssets(key).reference()
      .add(target);
  }
  public removeMediaContributorJobAssetEdmFromMediaContributorJobAssets(key: EntityKey<JobAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<MediaContributorJobAssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.mediaContributorJobAssets(key).reference()
      .remove(target);
  }
  //#endregion
}
