//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetParentAssetEdm } from './jobassetparentassetedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const JobAssetParentAssetEdmEntityConfig = {
  name: 'JobAssetParentAssetEdm',
  keys: [{name: 'jobAssetGuid'},{name: 'jobParentAssetGuid'}],
  fields: {
    jobAssetGuid: {type: 'Edm.Guid'},
    jobParentAssetGuid: {type: 'Edm.Guid'},
    jobAsset: {type: 'paplusApi.DataDomain.EFEntities.JobAssetEdm', navigation: true, referentials: [{property: 'jobAssetGuid', referencedProperty: 'jobAssetGuid'}]},
    jobParentAssetGUIDJobAsset: {type: 'paplusApi.DataDomain.EFEntities.JobAssetEdm', navigation: true, referentials: [{property: 'jobParentAssetGuid', referencedProperty: 'jobAssetGuid'}]}
  }
} as StructuredTypeConfig<JobAssetParentAssetEdm>;
//#endregion