//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from './assetedm.entity';
//#endregion

export interface AssetMediaEdm {
  //#region ODataApiGen Properties
  assetMediaGuid?: string;
  assetGuid?: string;
  description?: string;
  abbreviatedDescription: string;
  fileNamePart: string;
  type?: number;
  min?: number;
  max?: number;
  comment?: string;
  asset?: AssetEdm;
  //#endregion
}