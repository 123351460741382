//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonEdm } from './personedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonEdmEntityConfig = {
  name: 'PersonEdm',
  keys: [{name: 'personGuid'}],
  fields: {
    personGuid: {type: 'Edm.Guid', nullable: false},
    identityGuid: {type: 'Edm.Guid'},
    firstName: {type: 'Edm.String', nullable: false},
    lastName: {type: 'Edm.String', nullable: false},
    phoneNumber: {type: 'Edm.String', nullable: false},
    emailAddress: {type: 'Edm.String', nullable: false},
    profileImage: {type: 'Edm.Binary'},
    locked: {type: 'Edm.Boolean'},
    createdByAddresses: {type: 'paplusApi.DataDomain.EFEntities.AddressEdm', collection: true, navigation: true},
    personDevices: {type: 'paplusApi.DataDomain.EFEntities.PersonDeviceEdm', collection: true, navigation: true},
    personJobs: {type: 'paplusApi.DataDomain.EFEntities.PersonJobEdm', collection: true, navigation: true},
    personMediaContributors: {type: 'paplusApi.DataDomain.EFEntities.PersonMediaContributorEdm', collection: true, navigation: true},
    personMediaStatuses: {type: 'paplusApi.DataDomain.EFEntities.PersonMediaStatusEdm', collection: true, navigation: true},
    personOuRoles: {type: 'paplusApi.DataDomain.EFEntities.PersonOuRoleEdm', collection: true, navigation: true},
    projectPersonRoles: {type: 'paplusApi.DataDomain.EFEntities.ProjectPersonRoleEdm', collection: true, navigation: true},
    reportingPersonOuRoles: {type: 'paplusApi.DataDomain.EFEntities.PersonOuRoleEdm', collection: true, navigation: true},
    templates: {type: 'paplusApi.DataDomain.EFEntities.TemplateEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<PersonEdm>;
//#endregion