//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobsService } from './jobs.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const JobsServiceEntitySetConfig = {
  name: 'Jobs',
  entityType: 'paplusApi.DataDomain.EFEntities.JobEdm',
  service: JobsService
} as EntitySetConfig;
//#endregion