//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from './assetedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AssetEdmEntityConfig = {
  name: 'AssetEdm',
  keys: [{name: 'assetGuid'}],
  fields: {
    assetGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String', nullable: false, maxLength: -1},
    serialNumber: {type: 'Edm.String', maxLength: -1},
    notes: {type: 'Edm.String', nullable: false, maxLength: -1},
    abbreviatedDescription: {type: 'Edm.String'},
    metaData: {type: 'Edm.String', maxLength: -1},
    tag: {type: 'Edm.String'},
    groupInUi: {type: 'Edm.Boolean'},
    fileNamePart: {type: 'Edm.String'},
    iconGuid: {type: 'Edm.Guid'},
    hide: {type: 'Edm.Boolean'},
    icon: {type: 'paplusApi.DataDomain.EFEntities.IconEdm', navigation: true, referentials: [{property: 'iconGuid', referencedProperty: 'iconGuid'}]},
    assetMedias: {type: 'paplusApi.DataDomain.EFEntities.AssetMediaEdm', collection: true, navigation: true},
    assetParentAssets: {type: 'paplusApi.DataDomain.EFEntities.AssetParentAssetEdm', collection: true, navigation: true},
    assetTemplates: {type: 'paplusApi.DataDomain.EFEntities.AssetTemplateEdm', collection: true, navigation: true},
    parentAssetGuidAssetParentAssets: {type: 'paplusApi.DataDomain.EFEntities.AssetParentAssetEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<AssetEdm>;
//#endregion