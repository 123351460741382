import { filter } from 'rxjs/operators';
import {  ElementRef, Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TAB_PAGES } from '../constant/sidebar_list';





@Injectable({
  providedIn: 'root'
})
export class TabBarService {
  private tabBarRef: ElementRef | any;
  private tabBarTabs: Set<string> | any;

  constructor(private router: Router) {
   
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.setTabVisibility(event as RouterEvent);
      });
  }

  public init(tabBarRef: ElementRef, tabBarTabs: Set<string>): void {
    this.tabBarRef = tabBarRef;
    this.tabBarTabs = tabBarTabs;
  }

  public hideTabBar(): void {
    const display: string = this.tabBarRef.nativeElement.style.display;
    if (display !== 'none') {
      this.tabBarRef.nativeElement.style.display = 'none';
    }
  }

  public showTabBar(): void {
    const display: string =
      this.tabBarRef.nativeElement.style.display;
    if (display !== 'flex') {
      this.tabBarRef.nativeElement.style.display = 'flex';
    }
  }

  private setTabVisibility(event: RouterEvent) {
    const execResult: any = /.*\/([^?]+)/.exec(event.url);
   
    if (execResult) {
      const lastUrlPart: string = execResult[1];
      if (this.tabBarTabs.has(lastUrlPart)) {
        this.showTabBar();
      } else {
        this.hideTabBar();
      }
    } else {
      this.hideTabBar();
    }
  }

  public setCurrentPageTabs(tabslist: object[]=[]) {
    //const TABS_ROOT: string = 'app/tabs';
    const TABS_ROOT: string = 'app';

    let allTabs = JSON.parse(JSON.stringify(TAB_PAGES)) ;

    Array.prototype.push.apply(allTabs, tabslist); 

    let APP_PAGES: any[] = allTabs.map((page: any) => {
      page.url = '/' + TABS_ROOT + '/' + page.tab;
      return page;
    });

    return APP_PAGES;
  }

  public setPageTabs(tabslist: object[] = []) {
    return this.setCurrentPageTabs(tabslist).filter(page => page.inTabBar);
  }

  public setPageSlide(tabslist: object[] = []) {
    return this.setCurrentPageTabs(tabslist).filter(page => page.inSidemenu);
  }
}
