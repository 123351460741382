//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OuCloseoutPackageEdm } from './oucloseoutpackageedm.entity';
//#endregion

export interface CloseoutPackageEdm {
  //#region ODataApiGen Properties
  closeoutPackageGuid: string;
  fileFormat?: number;
  reportDefFileName?: string;
  description: string;
  script?: string;
  ouCloseoutPackages?: OuCloseoutPackageEdm[];
  //#endregion
}