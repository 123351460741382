//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
//#endregion

export interface NotificationTemplateEdm {
  //#region ODataApiGen Properties
  notificationTemplateGuid: string;
  template?: string;
  subject?: string;
  tag: string;
  //#endregion
}