import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { APP_PAGES } from './tabs/tabs-page/tabs-page';
import { Router } from '@angular/router';
import { MsalAuthenticationService } from './shared/services/msal.authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalEvents } from './shared/enum/events-enum';
import { PubSubService } from './shared/services/pub-sub.service';
import { CUSTOMERS_HEADER, JOBS_HEADER, PROJECTS_HEADER, USERS_HEADER } from './shared/constant/header_list';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { hasPermission } from './shared/utils/user-permissions.extensions';
import { PersonEdm } from './odata';
import { PAPlusUserAccountService } from './shared/services/paplus-account.service';
import { MsalService } from '@azure/msal-angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  private loggedInUser: PersonEdm;

  public appPages: any[] = APP_PAGES.filter(page => page.inSidemenu);
  public headerLink: any[] = JOBS_HEADER.filter(header => header.visible);
  public loggedIn = false;
  dark = false;
  innerWidth = 1366;

  //For side menu control==> Open by default
  sideMenuClass: string = 'side-menu-small';
  isMenuOpen = false;
  autoHide = false;


  constructor(
    private alertController: AlertController,
    private authService: MsalAuthenticationService,
    private modalController: ModalController,
    private msalService: MsalService,
    private paPlusUserService: PAPlusUserAccountService,
    public platform: Platform,
    private pubSub: PubSubService,
    private ref: ChangeDetectorRef,
    public router: Router,
    private translate: TranslateService
  ) {
    this.authService.initalize();
    this._initTranslate();
    pubSub.init();
    this.listenForEvents();


  }

  ngOnInit() {

  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.resizeSideMenu();
  }

  listenForEvents() {

    this.pubSub.subscribe(GlobalEvents.USER_LOGIN, (result) => {
      this.router.navigateByUrl('/app').then(() => this.updateLoggedInStatus(result[0]));
    })

    this.pubSub.subscribe(GlobalEvents.USER_LOGOUT, (result) => {
      this.updateLoggedInStatus(result[0]);
    })

    this.pubSub.subscribe(GlobalEvents.OPENCLOSEMENU, (result) => {
      this.isMenuOpen = result[0];
      this.toggleSideMenu();
    })

  }


  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
      this.ref.detectChanges();
    }, 300);
  }


  updateTabs(sidemenu: string, TABS: object[]) {
    if (TABS != undefined) {
      let tabArrayObject = [{ Tabs: TABS, parent: sidemenu }]
      this.pubSub.publish(GlobalEvents.PAGE_TABS, tabArrayObject);
    }

    switch (sidemenu) {
      case 'jobs':
        this.headerLink = JOBS_HEADER.filter(header => header.visible);
        break;
      case 'projects':
        this.headerLink = PROJECTS_HEADER.filter(header => header.visible);
        break;
      case 'users':
        this.headerLink = USERS_HEADER.filter(header => header.visible);
        break;
      case 'customers':
        this.headerLink = CUSTOMERS_HEADER.filter(header => header.visible);
        break
      default:
        this.headerLink = JOBS_HEADER.filter(header => header.visible);
        break;
    }
    this.hideMenu();

  }


  private _initTranslate() { // Set the default language for translation strings, and the current language.

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');
  }

  canReadPage(p: any) {
    if (!this.loggedInUser) {
      this.loadLoggedInUser();
    }

    return hasPermission(this.loggedInUser, p.permission);
  }

  toggleSideMenu() {
    // this.isMenuOpen = !this.isMenuOpen;
    this.sideMenuClass = this.sideMenuClass === 'side-menu-wide' ? 'side-menu-small' : 'side-menu-wide';
  }

  resizeSideMenu() {
    if (this.innerWidth >= 768 && this.innerWidth <= 1300) {
      //this.sideMenuClass = 'side-menu-small';
      //this.pubSub.publish(GlobalEvents.WINDOWRESIZE, [false]);
      this.autoHide = true;
      this.hideMenu();
    }
    if (this.innerWidth > 1300) {
      this.sideMenuClass = 'side-menu-wide';
      this.pubSub.publish(GlobalEvents.WINDOWRESIZE, [true]);
      this.autoHide = false;
    }
  }

  hideMenu() {
    if (this.autoHide) {
      this.sideMenuClass = 'side-menu-small';
      this.pubSub.publish(GlobalEvents.WINDOWRESIZE, [false]);
    }
  }

  async loadLoggedInUser() {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount != null) {
      this.paPlusUserService.retrievePaPlusUser(activeAccount.localAccountId).subscribe(async res => {
        this.loggedInUser = res;

        if (!hasPermission(this.loggedInUser, '_portalAccess')) {
          let alertButtons = [
            {
              text: 'OK',
              role: 'confirm',
              handler: () => {
                
                this.modalController.dismiss(
                  this.msalService.logout()
                );
              },
            },
          ];

          const alert = await this.alertController.create({
            header: "Unauthorised",
            //subHeader: 'A Sub Header Is Optional',
            message: "You do not have access to the PA+ Portal, you will now be logged out.",
            buttons: alertButtons
          });

          await alert.present();
        }
      });
    }
  }
}
