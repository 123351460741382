import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from "../components/confirmation-dialog/confirmation-dialog.component";

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
    ) {

    }

    openSnackBar(message: string, length: number, styleClass: string) {
        this.snackBar.open(message, '', { duration: length, panelClass: [styleClass] });
    }


    confirmationDialog(messagge: string, okMsg = 'Yes', cancelMsg = 'No', hideCancel = false) {
      return this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: messagge,
          buttonText: {
            ok: okMsg,
            cancel: cancelMsg
          },
          hideCancel: hideCancel
        },
          width: '30rem'
      });
    }

    handleError(error: any) {
        console.error(error);
        this.openSnackBar('The server operation has failed. Please contact the system administrator.', 4000, 'error-snackbar');
    }
}