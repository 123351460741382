//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetsService } from './jobassets.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const JobAssetsServiceEntitySetConfig = {
  name: 'JobAssets',
  entityType: 'paplusApi.DataDomain.EFEntities.JobAssetEdm',
  service: JobAssetsService
} as EntitySetConfig;
//#endregion