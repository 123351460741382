//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobEdm } from './jobedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const JobEdmEntityConfig = {
  name: 'JobEdm',
  keys: [{name: 'jobGuid'}],
  fields: {
    jobGuid: {type: 'Edm.Guid', nullable: false},
    projectGuid: {type: 'Edm.Guid'},
    ouguid: {type: 'Edm.Guid'},
    description: {type: 'Edm.String', maxLength: -1},
    lat: {type: 'Edm.Decimal', scale: 'variable'},
    lng: {type: 'Edm.Decimal', scale: 'variable'},
    what3Words: {type: 'Edm.String'},
    siteCode: {type: 'Edm.String'},
    customerJobCode: {type: 'Edm.String'},
    notes: {type: 'Edm.String', maxLength: -1},
    status: {type: 'Edm.Int16'},
    siteName: {type: 'Edm.String'},
    statusDate: {type: 'Edm.DateTimeOffset'},
    ou: {type: 'paplusApi.DataDomain.EFEntities.OuEdm', navigation: true, referentials: [{property: 'ouguid', referencedProperty: 'ouguid'}]},
    project: {type: 'paplusApi.DataDomain.EFEntities.ProjectEdm', navigation: true, referentials: [{property: 'projectGuid', referencedProperty: 'projectGuid'}]},
    jobAddresses: {type: 'paplusApi.DataDomain.EFEntities.JobAddressEdm', collection: true, navigation: true},
    jobTemplates: {type: 'paplusApi.DataDomain.EFEntities.JobTemplateEdm', collection: true, navigation: true},
    personJobs: {type: 'paplusApi.DataDomain.EFEntities.PersonJobEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<JobEdm>;
//#endregion