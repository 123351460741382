//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobTemplatesService } from './jobtemplates.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const JobTemplatesServiceEntitySetConfig = {
  name: 'JobTemplates',
  entityType: 'paplusApi.DataDomain.EFEntities.JobTemplateEdm',
  service: JobTemplatesService
} as EntitySetConfig;
//#endregion