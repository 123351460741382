//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAddressEdm } from './jobaddressedm.entity';
import { OuAddressEdm } from './ouaddressedm.entity';
import { PersonEdm } from './personedm.entity';
//#endregion

export interface AddressEdm {
  //#region ODataApiGen Properties
  addressGuid: string;
  line1?: string;
  line2?: string;
  city?: string;
  county?: string;
  countryCode?: string;
  country?: string;
  postcode?: string;
  createdOn?: Date;
  createdBy?: string;
  deleted?: Date;
  createdByPerson?: PersonEdm;
  jobAddresses?: JobAddressEdm[];
  ouAddresses?: OuAddressEdm[];
  //#endregion
}