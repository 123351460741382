//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DeviceEdm } from './deviceedm.entity';
import { PersonEdm } from './personedm.entity';
//#endregion

export interface PersonDeviceEdm {
  //#region ODataApiGen Properties
  personDeviceGuid: string;
  personGuid?: string;
  deviceGuid?: string;
  fromDateTime: Date;
  toDateTime?: Date;
  device?: DeviceEdm;
  person?: PersonEdm;
  //#endregion
}