//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobTemplateEdm } from './jobtemplateedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const JobTemplateEdmEntityConfig = {
  name: 'JobTemplateEdm',
  keys: [{name: 'jobTemplateGuid'}],
  fields: {
    jobTemplateGuid: {type: 'Edm.Guid', nullable: false},
    jobGuid: {type: 'Edm.Guid'},
    templateGuid: {type: 'Edm.Guid'},
    job: {type: 'paplusApi.DataDomain.EFEntities.JobEdm', navigation: true, referentials: [{property: 'jobGuid', referencedProperty: 'jobGuid'}]},
    template: {type: 'paplusApi.DataDomain.EFEntities.TemplateEdm', navigation: true, referentials: [{property: 'templateGuid', referencedProperty: 'templateGuid'}]},
    jobAssetTemplates: {type: 'paplusApi.DataDomain.EFEntities.JobAssetTemplateEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<JobTemplateEdm>;
//#endregion