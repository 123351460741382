import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DeviceEdm } from '../paplusApi/DataDomain/EFEntities/deviceedm.entity';
import { PersonDeviceEdm } from '../paplusApi/DataDomain/EFEntities/persondeviceedm.entity';
//#endregion

@Injectable()
export class DevicesService extends ODataEntitySetService<DeviceEdm> {
  constructor(client: ODataClient) {
    super(client, 'Devices', 'paplusApi.DataDomain.EFEntities.DeviceEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public personDevices(key: EntityKey<DeviceEdm>): ODataNavigationPropertyResource<PersonDeviceEdm> { 
    return this.entity(key).navigationProperty<PersonDeviceEdm>('personDevices'); 
  }
  public fetchPersonDevices(key: EntityKey<DeviceEdm>, options?: ODataQueryArgumentsOptions<PersonDeviceEdm>) {
    return this.fetchNavigationProperty<PersonDeviceEdm>(
      this.personDevices(key), 
      'entities', options) as Observable<ODataEntities<PersonDeviceEdm>>;
  }
  public addPersonDeviceEdmToPersonDevices(key: EntityKey<DeviceEdm>, target: ODataEntityResource<ODataEntities<PersonDeviceEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personDevices(key).reference()
      .add(target);
  }
  public removePersonDeviceEdmFromPersonDevices(key: EntityKey<DeviceEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonDeviceEdm>>, etag?: string} = {}): Observable<any> {
    return this.personDevices(key).reference()
      .remove(target);
  }
  //#endregion
}
