//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonMediaStatusEdm } from './personmediastatusedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonMediaStatusEdmEntityConfig = {
  name: 'PersonMediaStatusEdm',
  keys: [{name: 'personMediaStatusGuid'}],
  fields: {
    personMediaStatusGuid: {type: 'Edm.Guid', nullable: false},
    personGuid: {type: 'Edm.Guid'},
    jobAssetMediaGuid: {type: 'Edm.Guid'},
    mediaStatusGuid: {type: 'Edm.Guid'},
    fromUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    toUtcDateTime: {type: 'Edm.DateTimeOffset'},
    notes: {type: 'Edm.String', maxLength: -1},
    jobAssetMedia: {type: 'paplusApi.DataDomain.EFEntities.JobAssetMediaEdm', navigation: true, referentials: [{property: 'jobAssetMediaGuid', referencedProperty: 'jobAssetMediaGuid'}]},
    mediaStatus: {type: 'paplusApi.DataDomain.EFEntities.MediaStatusEdm', navigation: true, referentials: [{property: 'mediaStatusGuid', referencedProperty: 'mediaStatusGuid'}]},
    person: {type: 'paplusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<PersonMediaStatusEdm>;
//#endregion