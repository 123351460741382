//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AddressEdm } from './addressedm.entity';
import { OuEdm } from './ouedm.entity';
//#endregion

export interface OuAddressEdm {
  //#region ODataApiGen Properties
  ouguid?: string;
  addressGuid?: string;
  addressType?: number;
  manuallyEntered?: boolean;
  fromDate: Date;
  toDate?: Date;
  address?: AddressEdm;
  ou?: OuEdm;
  //#endregion
}