
<ion-app [class.dark-theme]="dark" [ngClass]="sideMenuClass">
  <app-main-header [headerMenus]="headerLink" [hidden]="!loggedIn"></app-main-header>
  <ion-split-pane when="sm" contentId="main-content" *ngIf="loggedIn">
    <ion-menu contentId="main-content"  type="overlay" *ngIf="loggedIn">
      <ion-content>

        <div *ngIf="isMenuOpen">
          <h1><b>Menu</b></h1>
        </div>

        <!--<ion-toolbar class="header-space">
          <img src="assets/img/side-menu-logo.png" alt="Photo Audit Plus" *ngIf="isMenuOpen">
          <ion-button [slot]="isMenuOpen? 'end':'start'" color="secondary" (click)="toggleSideMenu()">
            <ion-icon slot="icon-only" [name]="isMenuOpen? 'close':'menu'"></ion-icon>
          </ion-button>
        </ion-toolbar>-->

        <ion-toolbar class="menu-space">
          <ion-list id="pas-list">

            <ion-menu-toggle autoHide="false"
                             *ngFor="let p of appPages">
              <ion-item *ngIf="canReadPage(p)" [routerLink]="[p.url]" (click)="updateTabs(p.tab,p.TABS)"
                        #rla="routerLinkActive" [routerLinkActive]="['selected']"
                        routerDirection="root"
                        lines="none" detail="false" class="sidemenu-ion" [ngClass]="isMenuOpen?'':'sideRemovePad'">


                <div *ngIf="isMenuOpen" class="image">
                  <ion-img [src]="rla.isActive? p.iconActive : p.icon"></ion-img>
                </div>


                <ion-label *ngIf="isMenuOpen">{{p.title | translate}}</ion-label>

                <div [hidden]="isMenuOpen">
                  <ion-img slot="start" [src]="rla.isActive? p.iconActive : p.icon"></ion-img>
                </div>

              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-toolbar>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
  <!--<ion-router-outlet *ngIf="!loggedIn" id="main-content"></ion-router-outlet>-->

</ion-app>
