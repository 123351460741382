//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobEdm } from './jobedm.entity';
import { JobAssetTemplateEdm } from './jobassettemplateedm.entity';
import { TemplateEdm } from './templateedm.entity';
//#endregion

export interface JobTemplateEdm {
  //#region ODataApiGen Properties
  jobTemplateGuid: string;
  jobGuid?: string;
  templateGuid?: string;
  job?: JobEdm;
  template?: TemplateEdm;
  jobAssetTemplates?: JobAssetTemplateEdm[];
  //#endregion
}