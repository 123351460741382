//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from './assetedm.entity';
import { TemplateEdm } from './templateedm.entity';
//#endregion

export interface AssetTemplateEdm {
  //#region ODataApiGen Properties
  assetTemplateGuid?: string;
  templateGuid?: string;
  assetGuid?: string;
  fromUtcDateTime: Date;
  toUtcDateTime?: Date;
  asset?: AssetEdm;
  template?: TemplateEdm;
  //#endregion
}