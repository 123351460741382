//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PermissionEdm } from './permissionedm.entity';
import { RoleEdm } from './roleedm.entity';
//#endregion

export interface RolePermissionEdm {
  //#region ODataApiGen Properties
  permissionGuid?: string;
  roleGuid?: string;
  permission?: PermissionEdm;
  role?: RoleEdm;
  //#endregion
}