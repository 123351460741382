import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from '../paplusApi/DataDomain/EFEntities/assetedm.entity';
import { AssetMediaEdm } from '../paplusApi/DataDomain/EFEntities/assetmediaedm.entity';
//#endregion

@Injectable()
export class AssetMediasService extends ODataEntitySetService<AssetMediaEdm> {
  constructor(client: ODataClient) {
    super(client, 'AssetMedias', 'paplusApi.DataDomain.EFEntities.AssetMediaEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public asset(key: EntityKey<AssetMediaEdm>): ODataNavigationPropertyResource<AssetEdm> { 
    return this.entity(key).navigationProperty<AssetEdm>('asset'); 
  }
  public fetchAsset(key: EntityKey<AssetMediaEdm>, options?: ODataQueryArgumentsOptions<AssetEdm>) {
    return this.fetchNavigationProperty<AssetEdm>(
      this.asset(key), 
      'entity', options) as Observable<ODataEntity<AssetEdm>>;
  }
  public setAssetEdmAsAsset(key: EntityKey<AssetMediaEdm>, target: ODataEntityResource<ODataEntity<AssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.asset(key).reference()
      .set(target, {etag});
  }
  public unsetAssetEdmAsAsset(key: EntityKey<AssetMediaEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.asset(key).reference()
      .unset({etag});
  }
  //#endregion
}
