//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DeviceEdm } from './deviceedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const DeviceEdmEntityConfig = {
  name: 'DeviceEdm',
  keys: [{name: 'deviceGuid'}],
  fields: {
    deviceGuid: {type: 'Edm.Guid', nullable: false},
    imei: {type: 'Edm.String'},
    enabled: {type: 'Edm.Boolean'},
    description: {type: 'Edm.String', maxLength: -1},
    hostHeader: {type: 'Edm.String', maxLength: -1},
    personDevices: {type: 'paplusApi.DataDomain.EFEntities.PersonDeviceEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<DeviceEdm>;
//#endregion