import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CaptureEdm, JobAssetEdm } from '@app/odata';
import { ODataServiceFactory } from 'angular-odata';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CapturesService {

  private selectedCapture: BehaviorSubject<CaptureEdm | null> = new BehaviorSubject<CaptureEdm | null>(null);
  public selectedCapture$ = this.selectedCapture.asObservable();

  private selectedImage: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public selectedImage$ = this.selectedImage.asObservable();


  constructor(private http: HttpClient, private factory: ODataServiceFactory) { }

  getCapturesByJobAsset(jobAssetGuid: string): Observable<CaptureEdm[]> {
    let capturesService = this.factory.entitySet<CaptureEdm>(
        "CapturesByJobAsset(" + jobAssetGuid + ")",
        "paplusApi.DataDomain.EFEntities.CaptureEdm"
      );

     return capturesService.entities().fetch().pipe(
        map(res => 
            res.entities == null ? [] : res.entities)     
      );
  }

  getCapturesByJobAssetMedia(jobAssetMediaGuid: string): Observable<CaptureDTO[]> {
    let capturesService = this.factory.entitySet<CaptureDTO>(
        "CapturesWithImage",
         "paplusApi.DataDomain.EFEntities.CaptureEdm"
      );

      let captures = capturesService.entities();
      captures.query((q) => q.filter("JobAssetMediaGuid eq " + jobAssetMediaGuid))

     return captures.fetchEntities().pipe(
        map(res => 
            res == null ? [] : res)     
      );
  }

  getFileByCaptureGuid(captureGuid: string, fullsize: boolean) : Observable<Blob> {
    return this.http.get(`${environment.API}/CaptureFile(${captureGuid},${fullsize})`, {responseType: 'blob'});
  }

  uploadCapture(jobGuid: string, jobAssetGuid: string, jobAssetMediaGuid: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post(`${environment.API}/UploadCapture/${jobGuid}/${jobAssetGuid}/${jobAssetMediaGuid}`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  updateSelectedCapture(capture: CaptureEdm, fullsize: boolean) {
    this.selectedCapture.next(capture);
    this.getFileByCaptureGuid(capture.captureGuid, fullsize).subscribe(res => {
      this.selectedImage.next(URL.createObjectURL(res));
    });  
  }


}


export interface CaptureDTO {
  captureGuid: string;
  jobAssetMediaGuid?: string;
  createUtcDateTime: Date;
  notes?: string;
  mediaSource?: number;
  filePath: string;
  nativeFileName?: string;

  imageData?: ArrayBuffer;
  contentType?: string;
}