//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProjectProjectStatusEdm } from './projectprojectstatusedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ProjectProjectStatusEdmEntityConfig = {
  name: 'ProjectProjectStatusEdm',
  keys: [{name: 'projectProjectStatusGuid'}],
  fields: {
    projectProjectStatusGuid: {type: 'Edm.Guid', nullable: false},
    projectStatusGuid: {type: 'Edm.Guid'},
    projectGuid: {type: 'Edm.Guid'},
    fromUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    toUtcDateTime: {type: 'Edm.DateTimeOffset'},
    project: {type: 'paplusApi.DataDomain.EFEntities.ProjectEdm', navigation: true, referentials: [{property: 'projectGuid', referencedProperty: 'projectGuid'}]},
    projectStatus: {type: 'paplusApi.DataDomain.EFEntities.ProjectStatusEdm', navigation: true, referentials: [{property: 'projectStatusGuid', referencedProperty: 'projectStatusGuid'}]}
  }
} as StructuredTypeConfig<ProjectProjectStatusEdm>;
//#endregion