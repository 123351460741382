//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TemplatesService } from './templates.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const TemplatesServiceEntitySetConfig = {
  name: 'Templates',
  entityType: 'paplusApi.DataDomain.EFEntities.TemplateEdm',
  service: TemplatesService
} as EntitySetConfig;
//#endregion