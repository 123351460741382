//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RolesService } from './roles.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const RolesServiceEntitySetConfig = {
  name: 'Roles',
  entityType: 'paplusApi.DataDomain.EFEntities.RoleEdm',
  service: RolesService
} as EntitySetConfig;
//#endregion