//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProjectPersonRoleEdm } from './projectpersonroleedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ProjectPersonRoleEdmEntityConfig = {
  name: 'ProjectPersonRoleEdm',
  keys: [{name: 'projectPersonRoleGuid'}],
  fields: {
    projectGuid: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    roleGuid: {type: 'Edm.Guid'},
    fromUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    toUtcDateTime: {type: 'Edm.DateTimeOffset'},
    projectPersonRoleGuid: {type: 'Edm.Guid', nullable: false},
    person: {type: 'paplusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]},
    project: {type: 'paplusApi.DataDomain.EFEntities.ProjectEdm', navigation: true, referentials: [{property: 'projectGuid', referencedProperty: 'projectGuid'}]},
    role: {type: 'paplusApi.DataDomain.EFEntities.RoleEdm', navigation: true, referentials: [{property: 'roleGuid', referencedProperty: 'roleGuid'}]}
  }
} as StructuredTypeConfig<ProjectPersonRoleEdm>;
//#endregion