//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetEdm } from './jobassetedm.entity';
import { MediaContributorEdm } from './mediacontributoredm.entity';
//#endregion

export interface MediaContributorJobAssetEdm {
  //#region ODataApiGen Properties
  mediaContributorJobAssetGuid: string;
  mediaContributorGuid?: string;
  jobAssetGuid?: string;
  jobAsset?: JobAssetEdm;
  mediaContributor?: MediaContributorEdm;
  //#endregion
}