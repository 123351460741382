//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OuEdm } from './ouedm.entity';
import { PersonEdm } from './personedm.entity';
import { RoleEdm } from './roleedm.entity';
//#endregion

export interface PersonOuRoleEdm {
  //#region ODataApiGen Properties
  personOuRoleGuid?: string;
  personGuid?: string;
  roleGuid?: string;
  ouguid?: string;
  reportingPersonGuid?: string;
  fromUtcDateTime: Date;
  toUtcDateTime?: Date;
  ou?: OuEdm;
  person?: PersonEdm;
  reportingPerson?: PersonEdm;
  role?: RoleEdm;
  //#endregion
}