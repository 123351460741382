//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonEdm } from './personedm.entity';
import { ProjectEdm } from './projectedm.entity';
import { RoleEdm } from './roleedm.entity';
//#endregion

export interface ProjectPersonRoleEdm {
  //#region ODataApiGen Properties
  projectGuid?: string;
  personGuid?: string;
  roleGuid?: string;
  fromUtcDateTime: Date;
  toUtcDateTime?: Date;
  projectPersonRoleGuid?: string;
  person?: PersonEdm;
  project?: ProjectEdm;
  role?: RoleEdm;
  //#endregion
}