import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetMediaEdm } from '../paplusApi/DataDomain/EFEntities/jobassetmediaedm.entity';
import { MediaStatusEdm } from '../paplusApi/DataDomain/EFEntities/mediastatusedm.entity';
import { PersonEdm } from '../paplusApi/DataDomain/EFEntities/personedm.entity';
import { PersonMediaStatusEdm } from '../paplusApi/DataDomain/EFEntities/personmediastatusedm.entity';
//#endregion

@Injectable()
export class PersonMediaStatusesService extends ODataEntitySetService<PersonMediaStatusEdm> {
  constructor(client: ODataClient) {
    super(client, 'PersonMediaStatuses', 'paplusApi.DataDomain.EFEntities.PersonMediaStatusEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public jobAssetMedia(key: EntityKey<PersonMediaStatusEdm>): ODataNavigationPropertyResource<JobAssetMediaEdm> { 
    return this.entity(key).navigationProperty<JobAssetMediaEdm>('jobAssetMedia'); 
  }
  public fetchJobAssetMedia(key: EntityKey<PersonMediaStatusEdm>, options?: ODataQueryArgumentsOptions<JobAssetMediaEdm>) {
    return this.fetchNavigationProperty<JobAssetMediaEdm>(
      this.jobAssetMedia(key), 
      'entity', options) as Observable<ODataEntity<JobAssetMediaEdm>>;
  }
  public setJobAssetMediaEdmAsJobAssetMedia(key: EntityKey<PersonMediaStatusEdm>, target: ODataEntityResource<ODataEntity<JobAssetMediaEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobAssetMedia(key).reference()
      .set(target, {etag});
  }
  public unsetJobAssetMediaEdmAsJobAssetMedia(key: EntityKey<PersonMediaStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<JobAssetMediaEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobAssetMedia(key).reference()
      .unset({etag});
  }
  public mediaStatus(key: EntityKey<PersonMediaStatusEdm>): ODataNavigationPropertyResource<MediaStatusEdm> { 
    return this.entity(key).navigationProperty<MediaStatusEdm>('mediaStatus'); 
  }
  public fetchMediaStatus(key: EntityKey<PersonMediaStatusEdm>, options?: ODataQueryArgumentsOptions<MediaStatusEdm>) {
    return this.fetchNavigationProperty<MediaStatusEdm>(
      this.mediaStatus(key), 
      'entity', options) as Observable<ODataEntity<MediaStatusEdm>>;
  }
  public setMediaStatusEdmAsMediaStatus(key: EntityKey<PersonMediaStatusEdm>, target: ODataEntityResource<ODataEntity<MediaStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.mediaStatus(key).reference()
      .set(target, {etag});
  }
  public unsetMediaStatusEdmAsMediaStatus(key: EntityKey<PersonMediaStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<MediaStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.mediaStatus(key).reference()
      .unset({etag});
  }
  public person(key: EntityKey<PersonMediaStatusEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<PersonMediaStatusEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<PersonMediaStatusEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<PersonMediaStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  //#endregion
}
