import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MediaContributorEdm } from '../paplusApi/DataDomain/EFEntities/mediacontributoredm.entity';
import { PersonEdm } from '../paplusApi/DataDomain/EFEntities/personedm.entity';
import { PersonMediaContributorEdm } from '../paplusApi/DataDomain/EFEntities/personmediacontributoredm.entity';
//#endregion

@Injectable()
export class PersonMediaContributorsService extends ODataEntitySetService<PersonMediaContributorEdm> {
  constructor(client: ODataClient) {
    super(client, 'PersonMediaContributors', 'paplusApi.DataDomain.EFEntities.PersonMediaContributorEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public mediaContributor(key: EntityKey<PersonMediaContributorEdm>): ODataNavigationPropertyResource<MediaContributorEdm> { 
    return this.entity(key).navigationProperty<MediaContributorEdm>('mediaContributor'); 
  }
  public fetchMediaContributor(key: EntityKey<PersonMediaContributorEdm>, options?: ODataQueryArgumentsOptions<MediaContributorEdm>) {
    return this.fetchNavigationProperty<MediaContributorEdm>(
      this.mediaContributor(key), 
      'entity', options) as Observable<ODataEntity<MediaContributorEdm>>;
  }
  public setMediaContributorEdmAsMediaContributor(key: EntityKey<PersonMediaContributorEdm>, target: ODataEntityResource<ODataEntity<MediaContributorEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.mediaContributor(key).reference()
      .set(target, {etag});
  }
  public unsetMediaContributorEdmAsMediaContributor(key: EntityKey<PersonMediaContributorEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<MediaContributorEdm>>, etag?: string} = {}): Observable<any> {
    return this.mediaContributor(key).reference()
      .unset({etag});
  }
  public person(key: EntityKey<PersonMediaContributorEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<PersonMediaContributorEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<PersonMediaContributorEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<PersonMediaContributorEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  //#endregion
}
